import { gql } from '@apollo/client'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { Wrapper } from '@googlemaps/react-wrapper'
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Theme, alpha } from '@mui/system'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import { config } from '../../common/config/constants'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyLocationsQuery,
  CompanyType,
  LocationProperties,
  ProjectLocationsQuery,
  UserStatus,
  useCompanyLocationsQuery,
  useProjectLocationsQuery,
} from '../../common/graphql/apollo-operations'
import { formatLocationOneLine } from '../../common/util/Location'

gql`
  query projectLocations {
    projects {
      id
      name
      contracts {
        id
        users {
          id
          user {
            id
            email
          }
        }
      }

      location {
        ...LocationProperties
      }
    }
  }
  ${fragments.location}
`

gql`
  query companyLocations {
    companies {
      id
      name
      type
      users {
        id
        status
        user {
          id
          email
        }
      }
      locations {
        ...LocationProperties
      }
    }
  }
  ${fragments.location}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    '& .form': {
      position: 'absolute',
      top: theme.spacing(1),
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1,
      minWidth: 150,
      '& .selector': {
        backgroundColor: alpha(theme.palette.common.white, 1),
        fontWeight: 600,
      },
    },
  },
}))

enum DataSelection {
  Project = 'Projects',
  Sub = 'Subcontractors',
  Gc = 'General Contractors',
  Owner = 'Owners',
}

const SELECTION_KEYS = Object.keys(DataSelection) as (keyof typeof DataSelection)[]

type Project = ProjectLocationsQuery['projects'][number]
type Company = CompanyLocationsQuery['companies'][number]

// From our map we want to exclude demo companies & projects, as well as inactive companies
// This filter assumes that a demo company 1) has users, and 2) all users have a siteline email address
const isDemoCompany = (company: Company) =>
  company.users.length && company.users.every(({ user }) => user.email.includes('@siteline'))
// This filter assumes that an inactive company 1) has users, and 2) all users have an inactive status
const isInactiveCompany = (company: Company) =>
  company.users.length && company.users.every(({ status }) => status === UserStatus.DISABLED)
// This filter assumes that every user on a demo project has a siteline email address
const isDemoProject = (project: Project) =>
  project.contracts.every((contract) =>
    contract.users.every((contractUser) => contractUser.user.email.includes('@siteline'))
  )

/**
 * Adds a map of all project & company locations on Siteline
 */
export function CustomerLocationsMap() {
  const [dataSelection, setDataSelection] = useState<DataSelection>(DataSelection.Project)

  const { data: projectData } = useProjectLocationsQuery()
  const { data: companyData } = useCompanyLocationsQuery()

  const classes = useStyles()

  const projects = useMemo(
    () => (projectData?.projects ?? []).filter((project) => !isDemoProject(project)),
    [projectData?.projects]
  )
  const gcCompanies = useMemo(
    () =>
      (companyData?.companies ?? []).filter(
        (company) =>
          company.type === CompanyType.GENERAL_CONTRACTOR &&
          !isDemoCompany(company) &&
          !isInactiveCompany(company)
      ),
    [companyData?.companies]
  )
  const subCompanies = useMemo(
    () =>
      (companyData?.companies ?? []).filter(
        (company) =>
          company.type === CompanyType.SUBCONTRACTOR &&
          !isDemoCompany(company) &&
          !isInactiveCompany(company)
      ),
    [companyData?.companies]
  )
  const ownerCompanies = useMemo(
    () =>
      (companyData?.companies ?? []).filter(
        (company) =>
          company.type === CompanyType.OWNER_DEVELOPER &&
          !isDemoCompany(company) &&
          !isInactiveCompany(company)
      ),
    [companyData?.companies]
  )

  const handleSelectionUpdate = useCallback((event: SelectChangeEvent<DataSelection>) => {
    setDataSelection(event.target.value as DataSelection)
  }, [])

  return (
    <Page title="Location Map" className={classes.root}>
      <Wrapper apiKey={config.googleMapsApiKey} libraries={['places']}>
        <FormControl className="form">
          <Select
            value={dataSelection}
            input={<OutlinedInput />}
            onChange={handleSelectionUpdate}
            renderValue={(selected) => selected}
            className="selector"
          >
            {SELECTION_KEYS.map((selectionType) => {
              return (
                <MenuItem key={selectionType} value={DataSelection[selectionType]}>
                  <ListItemText primary={DataSelection[selectionType]} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <MapComponent
          projects={projects}
          gcCompanies={gcCompanies}
          ownerCompanies={ownerCompanies}
          subCompanies={subCompanies}
          dataSelection={dataSelection}
        />
      </Wrapper>
    </Page>
  )
}

interface GenerateMarkerParams {
  location: LocationProperties
  id: string
  hrefTitle: string
  map: google.maps.Map
  navigation: 'projects' | 'companies'
}

const generateMarker = ({ location, id, hrefTitle, map, navigation }: GenerateMarkerParams) => {
  const position = { lat: location.coordinates.latitude, lng: location.coordinates.longitude }
  const marker = new google.maps.Marker({
    position,
    label: { text: '1', color: 'white' },
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillColor: '#0000FF',
      strokeColor: '#0000FF',
      fillOpacity: 0.85,
      strokeWeight: 1,
    },
  })

  const locationName = location.nickname ?? formatLocationOneLine(location)

  const infoWindow = new google.maps.InfoWindow({
    // The content renders HTML if its in a string
    content: `
      <div>
        <a href="/${navigation}/${id}" target="_blank">${hrefTitle}</a>
        <br />
        Location: ${locationName}
      </div>
    `,
    disableAutoPan: true,
  })

  // Markers can only be keyboard focusable when they have click listeners
  // Open info window when marker is clicked
  marker.addListener('click', () => {
    infoWindow.open(map, marker)
  })

  return marker
}

const generateProjectMarkers = (projects: readonly Project[], map: google.maps.Map) => {
  return projects.map(({ location, id, name }) =>
    generateMarker({ location, id, hrefTitle: `Project: ${name}`, map, navigation: 'projects' })
  )
}

const generateCompanyMarkers = (companies: readonly Company[], map: google.maps.Map) => {
  const markers: google.maps.Marker[] = []
  companies.forEach(({ id, name, locations }) => {
    locations.forEach((location) =>
      markers.push(
        generateMarker({
          location,
          id,
          hrefTitle: `Company: ${name}`,
          map,
          navigation: 'companies',
        })
      )
    )
  })
  return markers
}

// Center on the US
const CENTER = { lat: 39.82, lng: -98.57 }
const ZOOM = 4

interface MapComponentProps {
  projects: readonly Project[]
  gcCompanies: readonly Company[]
  ownerCompanies: readonly Company[]
  subCompanies: readonly Company[]
  dataSelection: DataSelection
}

/**
 * Map component that puts the locations on the map, clustering them in dense areas
 */
function MapComponent({
  projects,
  gcCompanies,
  ownerCompanies,
  subCompanies,
  dataSelection,
}: MapComponentProps) {
  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!mapRef.current) {
      return
    }

    const map = new google.maps.Map(mapRef.current, {
      zoom: ZOOM,
      center: CENTER,
    })

    // Add markers to the map
    const projectMarkers =
      dataSelection === DataSelection.Project ? generateProjectMarkers(projects, map) : []
    const gcMarkers =
      dataSelection === DataSelection.Gc ? generateCompanyMarkers(gcCompanies, map) : []
    const ownerMarkers =
      dataSelection === DataSelection.Owner ? generateCompanyMarkers(ownerCompanies, map) : []
    const subMarkers =
      dataSelection === DataSelection.Sub ? generateCompanyMarkers(subCompanies, map) : []

    // Add a marker clusterer to manage the markers
    new MarkerClusterer({ markers: projectMarkers, map })
    new MarkerClusterer({ markers: gcMarkers, map })
    new MarkerClusterer({ markers: ownerMarkers, map })
    new MarkerClusterer({ markers: subMarkers, map })
  }, [dataSelection, gcCompanies, ownerCompanies, projects, subCompanies])

  return <div style={{ height: '100%', width: '100%' }} ref={mapRef} />
}
