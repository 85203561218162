import { boxesIntersect, SelectionBox, useSelectionContainer } from '@air/react-drag-to-select'
import {
  faDollarSign,
  faFeather,
  faICursor,
  faLaptop,
  faSignature,
  faStamp,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageIcon from '@mui/icons-material/Image'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import StarIcon from '@mui/icons-material/Star'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  AppBar,
  Button,
  ButtonProps,
  Card,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import _ from 'lodash'
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { createRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { configure as configureHotKeys, GlobalHotKeys, KeyMap, KeyMapOptions } from 'react-hotkeys'
import 'react-resizable/css/styles.css'
import { useMeasure } from 'react-use'
import { getSignatureUserVisibleName, pdfTypes, SignatureAnnotationType } from 'siteline-common-all'
import {
  FormTemplateAnnotationImageType,
  FormTemplateAnnotationMetadataFieldType,
  makeStylesFast,
} from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { Loader } from '../../../common/components/Loader'
import {
  AnnotationOverrideProperties,
  FormAnnotationInput,
  FormTemplateAnnotationProperties,
  FormTemplateAnnotationType,
  FormTemplateFont,
  FormTemplateProperties,
  FormTemplateType,
  FormTemplateVersionProperties,
  TextAlignment,
  UpdateFormAnnotationInput,
} from '../../../common/graphql/apollo-operations'
import { Document, Page } from '../../../pdf'
import {
  AnnotationGeometry,
  FORM_TEMPLATE_ANNOTATION_Z_INDEX,
  FormTemplateAnnotationLayer,
  FormTemplateAnnotationLayerHandle,
} from './FormTemplateAnnotationLayer'
import { PdfPageSelector } from './PdfPageSelector'
import { PreviewPosition } from './PreviewPositionSelector'

const useStyles = makeStylesFast((theme: Theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  pdfContainer: {
    // Disable selecting text by dragging, since it's most likely not useful
    // and may interferee with dragging to select annotations
    userSelect: 'none',
  },
  relative: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  annotationsContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}))

function CollapsibleButton({
  collapsed,
  startIcon,
  disabled,
  title,
  ...rest
}: Omit<ButtonProps, 'children'> &
  Omit<IconButtonProps, 'children'> & { title: string; collapsed: boolean; disabled: boolean }) {
  if (collapsed) {
    return (
      <Tooltip title={disabled ? 'Cannot create annotations on a variant' : title} placement="top">
        <span>
          <IconButton {...rest} size="medium" disabled={disabled}>
            {startIcon}
          </IconButton>
        </span>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={disabled ? 'Cannot create annotations on a variant' : null} placement="top">
      <span>
        <Button startIcon={startIcon} {...rest} disabled={disabled}>
          {title}
        </Button>
      </span>
    </Tooltip>
  )
}

configureHotKeys({
  // Allows user to hold down a key and it will continue to fire events. This is most useful for
  // holding down any of the arrow keys so that you can continue to scroll up, down, etc.
  ignoreRepeatedEventsWhenKeyHeldDown: false,
  // Allows user to touch other keys and it will continue to match the original key you pressed.
  // This is really helpful for navigating around between press and long-press (above config
  // option) and the browser won't receive the event. This prevents weird scrolling artifacts.
  allowCombinationSubmatches: true,
})

const MOVE_RELEASE: KeyMapOptions[] = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].map(
  (sequence) => ({
    sequence,
    action: 'keyup',
  })
)
const keyMap: KeyMap = {
  MOVE_UP: { sequence: 'ArrowUp', action: 'keydown' },
  MOVE_DOWN: { sequence: 'ArrowDown', action: 'keydown' },
  MOVE_LEFT: { sequence: 'ArrowLeft', action: 'keydown' },
  MOVE_RIGHT: { sequence: 'ArrowRight', action: 'keydown' },
  MOVE_RELEASE,
  SHIFT_DOWN: { sequence: 'SHIFT', action: 'keydown' },
  SHIFT_UP: { sequence: 'SHIFT', action: 'keyup' },
  ALIGN_TOP: 'SHIFT+t',
  ALIGN_LEFT: 'SHIFT+l',
  MATCH_WIDTH: 'SHIFT+w',
  MATCH_HEIGHT: 'SHIFT+h',
  SHOW_ANNOTATIONS: 'CTRL+h',
}

const PDF_CONTAINER_ID = 'pdfContainer'

type PopularAnnotationsMenuProps = {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
  onSelect: (annotation: pdfTypes.PopularAnnotation) => void
  popular: pdfTypes.PopularAnnotation[]
}

/**
 * A menu that shows the most popular annotations and their description.
 * Clicking on an annotation will add it to the PDF.
 */
function PopularAnnotationsMenu({
  popular,
  anchorEl,
  open,
  onClose,
  onSelect,
}: PopularAnnotationsMenuProps) {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose} MenuListProps={{ dense: true }}>
      {popular.map((annotation) => (
        <MenuItem onClick={() => onSelect(annotation)} key={`${annotation.type}-${annotation.key}`}>
          <ListItemIcon>
            {annotation.type === FormTemplateAnnotationType.TEMPLATE_VARIABLE && <TextFieldsIcon />}
            {annotation.type === FormTemplateAnnotationType.USER_ENTERED_FIELD && (
              <FontAwesomeIcon icon={faICursor} />
            )}
          </ListItemIcon>
          <Stack>
            <Typography variant="body1">
              <code>{annotation.key}</code>
            </Typography>
            <Typography variant="body2">{annotation.description}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </Menu>
  )
}

type FormTemplatePdfEditorProps = {
  formTemplate: FormTemplateProperties
  formTemplateVersion: FormTemplateVersionProperties
  selectedAnnotationIds: string[]
  onSelectedAnnotationIdsChange: (annotationIds: string[]) => void
  annotations: FormTemplateAnnotationProperties[]
  overrides: AnnotationOverrideProperties[]
  previewPosition: PreviewPosition
  setPreviewPosition: (position: PreviewPosition) => void
  createAnnotation: (annotationInput: Required<FormAnnotationInput>) => void
  templateVariables: pdfTypes.TemplateVariables | null
  onUpdate: (inputs: UpdateFormAnnotationInput[]) => void
  showAllAnnotationNames: boolean
  setShowAllAnnotationNames: (newValue: boolean) => void
  readOnly?: boolean
}

export function getTopLeftMostAnnotation<T>(
  annotations: T[],
  getGeometry: (annotation: T) => AnnotationGeometry | undefined
) {
  const orderedAnnotations = _.orderBy(annotations, [
    (annotation) => getGeometry(annotation)?.xStart ?? 0,
    (annotation) => getGeometry(annotation)?.yStart ?? 0,
  ])
  return _.first(orderedAnnotations)
}

function getTopLeftMostAnnotationRef(refs: RefObject<FormTemplateAnnotationLayerHandle>[]) {
  return getTopLeftMostAnnotation(refs, (ref) => ref.current?.getGeometry())
}

export function FormTemplatePdfEditor({
  previewPosition,
  setPreviewPosition,
  createAnnotation,
  onUpdate,
  formTemplate,
  formTemplateVersion,
  annotations,
  overrides,
  selectedAnnotationIds,
  onSelectedAnnotationIdsChange,
  templateVariables,
  showAllAnnotationNames,
  setShowAllAnnotationNames,
  readOnly = false,
}: FormTemplatePdfEditorProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const areButtonsCollapsed = isSmallScreen || previewPosition === 'right'
  const [pageNumber, setPageNumber] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [pageWidth, setPageWidth] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const [popularMenuAnchorEl, setPopularMenuAnchorEl] = useState<HTMLElement | null>(null)
  // Highlight the "anchor" annotation when multiple annotations are selected
  const [shouldHighlightAnchor, setShouldHighlightAnchor] = useState<boolean>(false)
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const visibleAnnotations = useMemo(
    () => annotations.filter((annotation) => annotation.pageNumber === pageNumber),
    [annotations, pageNumber]
  )
  const selectedAnnotations = useMemo(
    () =>
      visibleAnnotations.filter((annotation) =>
        selectedAnnotationIds.some((annotationId) => annotationId === annotation.id)
      ),
    [visibleAnnotations, selectedAnnotationIds]
  )
  const annotationIds = useMemo(() => annotations.map((annotation) => annotation.id), [annotations])
  const annotationRefsMap = useMemo(() => {
    return _.zipObject(
      annotationIds,
      annotationIds.map(() => createRef<FormTemplateAnnotationLayerHandle>())
    )
  }, [annotationIds])
  const selectedAnnotationRefs = useMemo(() => {
    return _.compact(selectedAnnotationIds.map((annotationId) => annotationRefsMap[annotationId]))
  }, [selectedAnnotationIds, annotationRefsMap])
  const annotationIdsRef = useRef<string[]>()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'signatureButton',
  })

  useEffect(() => {
    annotationIdsRef.current = [...visibleAnnotations.map((annotation) => annotation.id)]
  }, [visibleAnnotations])

  // Sets up drag-to-select, for selecting multiple annotations by clicking and dragging
  // See https://github.com/AirLabsTeam/react-drag-to-select
  const { DragSelection } = useSelectionContainer({
    // Only enable drag-to-select within the pdf
    eventsElement: document.getElementById(PDF_CONTAINER_ID),
    onSelectionChange: (box: SelectionBox) => {
      const annotationIdsToSelect: string[] = []
      annotationIdsRef.current?.forEach((annotationId) => {
        const element = document.getElementById(annotationId)
        const elementRect = element?.getBoundingClientRect()
        if (
          elementRect &&
          boxesIntersect(box, {
            left: elementRect.left,
            top: elementRect.top,
            width: elementRect.width,
            height: elementRect.height,
          })
        ) {
          annotationIdsToSelect.push(annotationId)
        }
      })
      onSelectedAnnotationIdsChange(annotationIdsToSelect)
    },
    shouldStartSelecting: (target) => {
      // Don't start drag-to-select when clicking an annotation
      if (target instanceof HTMLElement) {
        let el = target
        while (el.parentElement && !el.dataset.disableselect) {
          el = el.parentElement
        }
        return el.dataset.disableselect !== 'true'
      }
      return false
    },
    selectionProps: { style: { zIndex: FORM_TEMPLATE_ANNOTATION_Z_INDEX + 1 } },
  })
  const defaultAnnotationGeometry: AnnotationGeometry = useMemo(
    () => ({
      width: 10,
      height: 2,
      xStart: 50,
      yStart: 20,
    }),
    []
  )

  const addTemplateVariable = useCallback(
    (selectedKey?: string) => {
      const key = selectedKey ?? 'project.name'
      createAnnotation({
        id: uuidv4(),
        type: FormTemplateAnnotationType.TEMPLATE_VARIABLE,
        selectedKey: key,
        fontColor: '000000',
        fontFamily: FormTemplateFont.ARIAL_NORMAL,
        pageNumber,
        textAlignment: TextAlignment.LEFT,
        wrapText: false,
        userVisibleName: key,
        ...defaultAnnotationGeometry,
        copyDefaultValueFromPreviousAnnotationValue: false,
        defaultValueKey: null,
        doNotRetainOnReset: false,
        dynamicFieldTag: null,
        fieldType: null,
        imageType: null,
        isOptional: false,
        prefix: null,
        suffix: null,
        signatureType: null,
      })
    },
    [createAnnotation, defaultAnnotationGeometry, pageNumber]
  )

  const addUserEnteredField = useCallback(
    (defaultValueKey?: string) => {
      createAnnotation({
        id: uuidv4(),
        type: FormTemplateAnnotationType.USER_ENTERED_FIELD,
        defaultValueKey: defaultValueKey ?? 'project.name',
        fontColor: '000000',
        fontFamily: FormTemplateFont.ARIAL_NORMAL,
        pageNumber,
        textAlignment: TextAlignment.LEFT,
        wrapText: false,
        userVisibleName: 'Field',
        ...defaultAnnotationGeometry,
        copyDefaultValueFromPreviousAnnotationValue: false,
        doNotRetainOnReset: false,
        dynamicFieldTag: null,
        fieldType: null,
        imageType: null,
        isOptional: false,
        prefix: null,
        selectedKey: null,
        suffix: null,
        signatureType: null,
      })
    },
    [createAnnotation, defaultAnnotationGeometry, pageNumber]
  )

  const addSignature = useCallback(
    (signatureType: SignatureAnnotationType) => {
      const userVisibleName = getSignatureUserVisibleName(signatureType)
      createAnnotation({
        id: uuidv4(),
        type: FormTemplateAnnotationType.SIGNATURE,
        signatureType,
        fontColor: '000000',
        fontFamily: FormTemplateFont.ARIAL_NORMAL,
        pageNumber,
        textAlignment: TextAlignment.LEFT,
        wrapText: false,
        userVisibleName,
        xStart: defaultAnnotationGeometry.xStart,
        yStart: defaultAnnotationGeometry.yStart,
        width: 30,
        height: 3,
        copyDefaultValueFromPreviousAnnotationValue: false,
        defaultValueKey: null,
        doNotRetainOnReset: false,
        dynamicFieldTag: null,
        fieldType: null,
        imageType: null,
        isOptional: false,
        prefix: null,
        selectedKey: null,
        suffix: null,
      })
    },
    [
      createAnnotation,
      defaultAnnotationGeometry.xStart,
      defaultAnnotationGeometry.yStart,
      pageNumber,
    ]
  )

  const addLienWaiverAmount = useCallback(() => {
    createAnnotation({
      id: uuidv4(),
      type: FormTemplateAnnotationType.USER_ENTERED_FIELD,
      fontColor: '000000',
      fontFamily: FormTemplateFont.ARIAL_NORMAL,
      pageNumber,
      textAlignment: TextAlignment.LEFT,
      wrapText: false,
      userVisibleName: 'Amount of Check',
      defaultValueKey: 'currentPaymentDue',
      fieldType: FormTemplateAnnotationMetadataFieldType.LIEN_WAIVER_AMOUNT,
      doNotRetainOnReset: true,
      ...defaultAnnotationGeometry,
      copyDefaultValueFromPreviousAnnotationValue: false,
      dynamicFieldTag: null,
      imageType: null,
      isOptional: false,
      prefix: null,
      selectedKey: null,
      suffix: null,
      signatureType: null,
    })
  }, [createAnnotation, defaultAnnotationGeometry, pageNumber])

  const addImage = useCallback(() => {
    createAnnotation({
      id: uuidv4(),
      type: FormTemplateAnnotationType.IMAGE,
      fontColor: '000000',
      fontFamily: FormTemplateFont.ARIAL_NORMAL,
      pageNumber,
      textAlignment: TextAlignment.LEFT,
      wrapText: false,
      userVisibleName: 'Image',
      imageType: FormTemplateAnnotationImageType.SUBCONTRACTOR_LOGO,
      xStart: defaultAnnotationGeometry.xStart,
      yStart: defaultAnnotationGeometry.yStart,
      width: 10,
      height: 10,
      copyDefaultValueFromPreviousAnnotationValue: false,
      defaultValueKey: null,
      doNotRetainOnReset: false,
      dynamicFieldTag: null,
      fieldType: null,
      isOptional: false,
      prefix: null,
      selectedKey: null,
      suffix: null,
      signatureType: null,
    })
  }, [
    createAnnotation,
    defaultAnnotationGeometry.xStart,
    defaultAnnotationGeometry.yStart,
    pageNumber,
  ])

  const handlePopularAnnotation = useCallback(
    (annotation: pdfTypes.PopularAnnotation) => {
      setPopularMenuAnchorEl(null)
      switch (annotation.type) {
        case FormTemplateAnnotationType.TEMPLATE_VARIABLE:
          return addTemplateVariable(annotation.key)
        case FormTemplateAnnotationType.USER_ENTERED_FIELD:
          return addUserEnteredField(annotation.key)
        case FormTemplateAnnotationType.IMAGE:
        case FormTemplateAnnotationType.SIGNATURE:
          console.error('Not supported')
      }
    },
    [addTemplateVariable, addUserEnteredField]
  )

  const popular: pdfTypes.PopularAnnotation[] = useMemo(() => {
    switch (formTemplate.type) {
      case FormTemplateType.CHANGE_ORDER_REQUEST:
        return templateVariables?.popular.changeOrderRequest ?? []
      case FormTemplateType.CHANGE_ORDER_LOG:
        return templateVariables?.popular.changeOrderLog ?? []
      case FormTemplateType.LEGAL_DOCUMENT:
        return templateVariables?.popular.legalRequirement ?? []
      case FormTemplateType.LIEN_WAIVER:
        return templateVariables?.popular.lienWaiver ?? []
      case FormTemplateType.PAY_APP_LUMP_SUM:
        return templateVariables?.popular.payAppLumpSum ?? []
      case FormTemplateType.PAY_APP_QUICK:
        return templateVariables?.popular.payAppQuick ?? []
      case FormTemplateType.PAY_APP_UNIT_PRICE:
        return templateVariables?.popular.payAppUnitPrice ?? []
      case FormTemplateType.PAY_APP_TIME_AND_MATERIALS:
        return templateVariables?.popular.payAppTimeAndMaterials ?? []
    }
  }, [
    formTemplate.type,
    templateVariables?.popular.changeOrderRequest,
    templateVariables?.popular.changeOrderLog,
    templateVariables?.popular.legalRequirement,
    templateVariables?.popular.lienWaiver,
    templateVariables?.popular.payAppLumpSum,
    templateVariables?.popular.payAppQuick,
    templateVariables?.popular.payAppTimeAndMaterials,
    templateVariables?.popular.payAppUnitPrice,
  ])

  const saveTranslation = useCallback(() => {
    onUpdate(
      _.compact(
        selectedAnnotations.map((annotation) => {
          const ref = _.get(annotationRefsMap, annotation.id, undefined)
          if (!ref?.current) {
            return { id: annotation.id }
          }
          const { xStart, yStart } = ref.current.getGeometry()
          return { id: annotation.id, xStart, yStart }
        })
      )
    )
  }, [selectedAnnotations, annotationRefsMap, onUpdate])

  /**
   * Save translations with a debounce so we only make the API mutation when the
   * user has finished moving the annotations
   */
  const debouncedSaveTranslation = useMemo(
    () => _.debounce(saveTranslation, 1000),
    [saveTranslation]
  )

  const translateSelectedCells = useCallback(
    (direction: 'up' | 'down' | 'left' | 'right') => {
      debouncedSaveTranslation.cancel()
      let translation = { x: 0, y: 0 }
      switch (direction) {
        case 'up':
          translation = { x: 0, y: -0.1 }
          break
        case 'down':
          translation = { x: 0, y: 0.1 }
          break
        case 'left':
          translation = { x: -0.1, y: 0 }
          break
        case 'right':
          translation = { x: 0.1, y: 0 }
          break
      }
      selectedAnnotationRefs.forEach((ref) => ref.current?.translate(translation.x, translation.y))
    },
    [selectedAnnotationRefs, debouncedSaveTranslation]
  )

  const handleTopAlignAnnotations = useCallback(() => {
    debouncedSaveTranslation.cancel()
    const topLeftMostAnnotationRef = getTopLeftMostAnnotationRef(selectedAnnotationRefs)
    const leftmostAnnotationY = topLeftMostAnnotationRef?.current?.getGeometry().xStart
    if (leftmostAnnotationY !== undefined) {
      selectedAnnotationRefs.forEach((ref) =>
        ref.current?.setGeometry({ xStart: leftmostAnnotationY })
      )
      debouncedSaveTranslation()
    }
  }, [selectedAnnotationRefs, debouncedSaveTranslation])

  const handleLeftAlignAnnotations = useCallback(() => {
    debouncedSaveTranslation.cancel()
    const topLeftMostAnnotationRef = getTopLeftMostAnnotationRef(selectedAnnotationRefs)
    const leftmostAnnotationY = topLeftMostAnnotationRef?.current?.getGeometry().yStart
    if (leftmostAnnotationY !== undefined) {
      selectedAnnotationRefs.forEach((ref) =>
        ref.current?.setGeometry({ yStart: leftmostAnnotationY })
      )
      debouncedSaveTranslation()
    }
  }, [selectedAnnotationRefs, debouncedSaveTranslation])

  const handleMatchAnnotationWidths = useCallback(() => {
    const topLeftMostAnnotationRef = getTopLeftMostAnnotationRef(selectedAnnotationRefs)
    const leftmostAnnotationWidth = topLeftMostAnnotationRef?.current?.getGeometry().width
    if (leftmostAnnotationWidth !== undefined) {
      onUpdate(
        selectedAnnotationIds.map((annotationId) => ({
          id: annotationId,
          width: leftmostAnnotationWidth,
        }))
      )
    }
  }, [selectedAnnotationRefs, selectedAnnotationIds, onUpdate])

  const handleMatchAnnotationHeights = useCallback(() => {
    const topLeftMostAnnotationRef = getTopLeftMostAnnotationRef(selectedAnnotationRefs)
    const leftmostAnnotationHeight = topLeftMostAnnotationRef?.current?.getGeometry().height
    if (leftmostAnnotationHeight !== undefined) {
      onUpdate(
        selectedAnnotationIds.map((annotationId) => ({
          id: annotationId,
          height: leftmostAnnotationHeight,
        }))
      )
    }
  }, [selectedAnnotationRefs, selectedAnnotationIds, onUpdate])

  const handleDeselectAnnotations = useCallback(() => {
    onSelectedAnnotationIdsChange([])
  }, [onSelectedAnnotationIdsChange])

  const handlers = useMemo(
    () => ({
      MOVE_UP: (event?: KeyboardEvent) => {
        translateSelectedCells('up')
        event?.preventDefault()
      },
      MOVE_DOWN: (event?: KeyboardEvent) => {
        translateSelectedCells('down')
        event?.preventDefault()
      },
      MOVE_LEFT: (event?: KeyboardEvent) => {
        translateSelectedCells('left')
        event?.preventDefault()
      },
      MOVE_RIGHT: (event?: KeyboardEvent) => {
        translateSelectedCells('right')
        event?.preventDefault()
      },
      MOVE_RELEASE: (event?: KeyboardEvent) => {
        debouncedSaveTranslation()
        event?.preventDefault()
      },
      SHIFT_DOWN: () => {
        setShouldHighlightAnchor(true)
      },
      SHIFT_UP: () => {
        setShouldHighlightAnchor(false)
      },
      ALIGN_TOP: () => {
        handleLeftAlignAnnotations()
      },
      ALIGN_LEFT: () => {
        handleTopAlignAnnotations()
      },
      MATCH_WIDTH: () => {
        handleMatchAnnotationWidths()
      },
      MATCH_HEIGHT: () => {
        handleMatchAnnotationHeights()
      },
      SHOW_ANNOTATIONS: () => {
        setShowAllAnnotationNames(!showAllAnnotationNames)
      },
    }),
    [
      debouncedSaveTranslation,
      handleLeftAlignAnnotations,
      handleMatchAnnotationHeights,
      handleMatchAnnotationWidths,
      handleTopAlignAnnotations,
      setShowAllAnnotationNames,
      showAllAnnotationNames,
      translateSelectedCells,
    ]
  )

  const sortedPopular = _.orderBy(popular, (annotation) => annotation.key, 'asc')
  // If multiple annotations are selected and the shift key is held, highlight the
  // "anchor" annotation that others may be positioned to match
  const anchorAnnotation =
    shouldHighlightAnchor &&
    selectedAnnotations.length > 1 &&
    getTopLeftMostAnnotation(selectedAnnotations, _.identity)

  const signatureMenuItems = useMemo(() => {
    const digital = (
      <MenuItem
        key="digital"
        onClick={() => {
          popupState.close()
          addSignature(SignatureAnnotationType.DIGITAL)
        }}
        sx={{ gap: 1 }}
      >
        <FontAwesomeIcon icon={faLaptop} style={{ width: 24 }} />
        Digital signature
      </MenuItem>
    )
    if (formTemplate.type === FormTemplateType.CHANGE_ORDER_REQUEST) {
      return [digital]
    }
    return [
      digital,
      <MenuItem
        key="notary"
        onClick={() => {
          popupState.close()
          addSignature(SignatureAnnotationType.NOTARY)
        }}
        sx={{ gap: 1 }}
      >
        <FontAwesomeIcon icon={faStamp} style={{ width: 24 }} />
        Notary signature
      </MenuItem>,
      <MenuItem
        key="wet"
        onClick={() => {
          popupState.close()
          addSignature(SignatureAnnotationType.WET)
        }}
        sx={{ gap: 1 }}
      >
        <FontAwesomeIcon icon={faUserGroup} style={{ width: 24 }} />
        Wet signature
      </MenuItem>,
      <MenuItem
        key="witness"
        onClick={() => {
          popupState.close()
          addSignature(SignatureAnnotationType.WITNESS)
        }}
        sx={{ gap: 1 }}
      >
        <FontAwesomeIcon icon={faFeather} style={{ width: 24 }} />
        Witness signature
      </MenuItem>,
    ]
  }, [addSignature, formTemplate.type, popupState])

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <AppBar position="static" color="transparent" elevation={1}>
        <Toolbar variant="dense">
          <CollapsibleButton
            collapsed={areButtonsCollapsed}
            size="small"
            className={classes.button}
            startIcon={<TextFieldsIcon fontSize="small" />}
            onClick={() => addTemplateVariable()}
            disabled={readOnly}
            title="Variable"
          />
          <CollapsibleButton
            collapsed={areButtonsCollapsed}
            size="small"
            className={classes.button}
            startIcon={<FontAwesomeIcon icon={faICursor} fontSize={16} />}
            onClick={() => addUserEnteredField()}
            disabled={readOnly}
            title="Field"
          />
          <CollapsibleButton
            collapsed={areButtonsCollapsed}
            size="small"
            className={classes.button}
            startIcon={<FontAwesomeIcon icon={faSignature} fontSize={16} />}
            onClick={() => addSignature(SignatureAnnotationType.DIGITAL)}
            disabled={readOnly}
            title="Signature"
            {...bindHover(popupState)}
          />
          <HoverMenu {...bindMenu(popupState)}>{signatureMenuItems}</HoverMenu>
          {[
            FormTemplateType.LIEN_WAIVER,
            FormTemplateType.PAY_APP_LUMP_SUM,
            FormTemplateType.PAY_APP_UNIT_PRICE,
          ].includes(formTemplate.type) && (
            <CollapsibleButton
              collapsed={areButtonsCollapsed}
              size="small"
              className={classes.button}
              startIcon={<FontAwesomeIcon icon={faDollarSign} fontSize={16} />}
              onClick={addLienWaiverAmount}
              disabled={readOnly}
              title="Amount"
            />
          )}
          <CollapsibleButton
            collapsed={areButtonsCollapsed}
            size="small"
            className={classes.button}
            startIcon={<ImageIcon fontSize="small" />}
            onClick={addImage}
            disabled={readOnly}
            title="Image"
          />
          <CollapsibleButton
            collapsed={areButtonsCollapsed}
            size="small"
            className={classes.button}
            startIcon={<StarIcon fontSize="small" />}
            onClick={(ev) => setPopularMenuAnchorEl(ev.currentTarget)}
            disabled={readOnly}
            title="Popular"
          />

          <span style={{ flex: 1 }}></span>
          <PdfPageSelector
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
          />
          <Tooltip
            title={
              showAllAnnotationNames
                ? 'Hide all annotations (ctrl+h)'
                : 'Show all annotations (ctrl+h)'
            }
          >
            <IconButton onClick={() => setShowAllAnnotationNames(!showAllAnnotationNames)}>
              {showAllAnnotationNames ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Tooltip>
          {previewPosition === 'bottom' && (
            <Tooltip title="Show preview on the right" placement="top">
              <IconButton onClick={() => setPreviewPosition('right')}>
                <ViewWeekOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {previewPosition === 'right' && (
            <Tooltip title="Show preview at the bottom" placement="top">
              <IconButton onClick={() => setPreviewPosition('bottom')}>
                <SpaceDashboardOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Card>
        <div
          ref={ref}
          // Used for identifying where to accept clicks for drag-to-select
          id="pdfContainer"
          className={classes.pdfContainer}
          onMouseDown={(evt) => {
            // If the user clicks outside the annotations (without holding shift and not while dragging),
            // deselect all annotations. If the click is on an annotation, `stopPropagation` is called
            // and the event won't bubble up to this listener.
            if (!evt.shiftKey) {
              handleDeselectAnnotations()
            }
          }}
        >
          <div className={classes.relative}>
            <Document
              file={formTemplateVersion.file.url}
              // It's possible that this success callback is triggered with a null value
              onLoadSuccess={(document: { numPages: number } | null) => {
                if (document !== null) {
                  setPageCount(document.numPages)
                }
              }}
              loading={<Loader />}
            >
              <Page
                pageNumber={pageNumber}
                // Only pass width in when we're zoomed in. This will allow the page to take the entire available width of its container,
                // with the height adjusted accordingly. If we're zoomed out, we'll pass in the height and allow the width
                // to be calculated based on height instead
                width={width}
                onRenderSuccess={(page) => {
                  // If page is rotated by 90 or 270 degrees, we need to swap the width and height
                  // because the annotations are in an incorrect coordinate system.
                  // Note that the PDF still renders normally in react-pdf and in the
                  // PDF service (which removes the rotation via qpdf).
                  if (page.rotate === 270 || page.rotate === 90) {
                    setPageWidth(page.height)
                    setPageHeight(page.width)

                    // If page has a 0 or 180 degree rotation, pass the regular width/height.
                  } else if (page.rotate === 0 || page.rotate === 180) {
                    setPageWidth(page.width)
                    setPageHeight(page.height)

                    // We can't handle other types of rotations.
                  } else {
                    throw new Error(`Invalid page rotation ${page.rotate}`)
                  }
                }}
              />
            </Document>
            <DragSelection />
            <div className={classes.annotationsContainer}>
              <div className={classes.relative}>
                {visibleAnnotations.map((annotation) => {
                  const applicableOverride = overrides.find(
                    (override) => override.annotationPermanentId === annotation.permanentId
                  )
                  return (
                    <FormTemplateAnnotationLayer
                      ref={_.get(annotationRefsMap, annotation.id)}
                      key={annotation.id}
                      annotation={annotation}
                      override={applicableOverride ?? null}
                      pageWidth={pageWidth}
                      pageHeight={pageHeight}
                      isSelected={selectedAnnotationIds.includes(annotation.id)}
                      isAnchorAnnotation={
                        anchorAnnotation ? anchorAnnotation.id === annotation.id : false
                      }
                      onSelect={(asMultipleSelection) => {
                        const isSelected = selectedAnnotationIds.includes(annotation.id)
                        if (isSelected && asMultipleSelection) {
                          onSelectedAnnotationIdsChange(
                            selectedAnnotationIds.filter(
                              (annotationId) => annotationId !== annotation.id
                            )
                          )
                        } else {
                          onSelectedAnnotationIdsChange(
                            asMultipleSelection
                              ? [...selectedAnnotationIds, annotation.id]
                              : [annotation.id]
                          )
                        }
                      }}
                      onUpdate={onUpdate}
                      showAllAnnotationNames={showAllAnnotationNames}
                      readOnly={readOnly}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <PopularAnnotationsMenu
          anchorEl={popularMenuAnchorEl}
          open={popularMenuAnchorEl !== null}
          onClose={() => setPopularMenuAnchorEl(null)}
          onSelect={handlePopularAnnotation}
          popular={sortedPopular}
        />
      </Card>
    </>
  )
}
