import { gql } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import shadows from '@mui/material/styles/shadows'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { decimalToPercent, percentToDecimal } from 'siteline-common-all'
import { colors } from 'siteline-common-web'
import { ComplianceUseQuery } from '../../common/graphql/apollo-operations'
import { getSmallChartXAxisProps } from '../../common/util/Chart'
import { ChartTitleAndArrows } from './ChartTitleAndArrows'
import {
  CompanyForFeatureUsage,
  FeatureUsageSortBy,
  NUM_COMPANIES_PER_FEATURE_CHART_PAGE,
} from './FeatureUsageDashboard'

gql`
  query complianceUse {
    complianceUse {
      companyId
      companyName
      companyCreatedAt
      percentProjectsWithComplianceRequirements
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

export function percentFormatter(value: number) {
  return `${value}%`
}

const PERCENT_PROJECTS_KEY = '% Projects with Compliance'

interface ComplianceUsageChartProps {
  data: ComplianceUseQuery['complianceUse'] | undefined
  companies: CompanyForFeatureUsage[]
  filterCompanyIds: string[]
  accountOwner: string | null
  sortBy: FeatureUsageSortBy
  isSmallScreen: boolean
}

/** Chart for tracking number of projects using compliance per customer */
export function ComplianceUsageChart({
  data,
  companies,
  filterCompanyIds,
  accountOwner,
  sortBy,
  isSmallScreen,
}: ComplianceUsageChartProps) {
  const [startIndex, setStartIndex] = useState<number>(0)

  // If the sort by or filters change, reset the start index to 0
  useEffect(() => {
    setStartIndex(0)
  }, [sortBy, filterCompanyIds])

  const chartData = useMemo(() => {
    return _.chain(data ?? [])
      .map(
        ({
          companyName,
          companyId,
          companyCreatedAt,
          sitelinePointOfContactId,
          percentProjectsWithComplianceRequirements,
        }) => {
          return {
            companyId,
            companyName,
            companyCreatedAt: moment.utc(companyCreatedAt).unix(),
            sitelinePointOfContactId,
            [PERCENT_PROJECTS_KEY]: decimalToPercent(percentProjectsWithComplianceRequirements, 1),
          }
        }
      )
      .unionBy(
        companies.map((company) => ({ ...company, [PERCENT_PROJECTS_KEY]: 0 })),
        (company) => company.companyId
      )
      .compact()
      .filter(({ companyId }) => {
        return filterCompanyIds.length === 0 || filterCompanyIds.includes(companyId)
      })
      .filter((companyData) => {
        return !accountOwner || companyData.sitelinePointOfContactId === accountOwner
      })
      .orderBy((data) => {
        switch (sortBy) {
          case 'companyName':
            return data['companyName']
          case 'customerAge':
            return -1 * Number(data['companyCreatedAt'])
          case 'usageAsc':
            return percentToDecimal(data[PERCENT_PROJECTS_KEY])
          case 'usageDesc':
            return -1 * percentToDecimal(data[PERCENT_PROJECTS_KEY])
          case null:
            return ''
        }
      })
      .value()
  }, [accountOwner, companies, data, filterCompanyIds, sortBy])

  const numCompaniesInChart = chartData.length
  const currentPageChartData = useMemo(
    () =>
      chartData.slice(
        startIndex,
        Math.min(startIndex + NUM_COMPANIES_PER_FEATURE_CHART_PAGE, numCompaniesInChart)
      ),
    [chartData, numCompaniesInChart, startIndex]
  )

  const {
    labelLength: xAxisLabelLength,
    xAxisHeight,
    tickAngle,
    labelAnchor: xAxisLabelAnchor,
  } = useMemo(() => getSmallChartXAxisProps(isSmallScreen), [isSmallScreen])

  return (
    <div style={{ boxShadow: shadows['4'] }}>
      <ChartTitleAndArrows
        title="Projects with Compliance Requirements"
        startIndex={startIndex}
        onStartIndexChange={setStartIndex}
        numCompaniesInChart={numCompaniesInChart}
        numCompaniesPerPage={NUM_COMPANIES_PER_FEATURE_CHART_PAGE}
      />
      {data && (
        <ResponsiveContainer height={500} width="100%" style={{ marginTop: 16 }}>
          <BarChart data={currentPageChartData} margin={{ bottom: 32, left: 32, right: 32 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="companyName"
              tick={{ fontSize: 12 }}
              interval={0}
              tickFormatter={(companyName) => _.truncate(companyName, { length: xAxisLabelLength })}
              angle={tickAngle}
              height={xAxisHeight}
              textAnchor={xAxisLabelAnchor}
            />
            <YAxis tickFormatter={percentFormatter} domain={[0, 100]}>
              <Label
                angle={-90}
                value="% projects using compliance"
                offset={8}
                position="left"
                orientation="vertical"
              />
            </YAxis>
            <Tooltip formatter={percentFormatter} />
            <Bar dataKey={PERCENT_PROJECTS_KEY} fill={colors.blue70} />
          </BarChart>
        </ResponsiveContainer>
      )}
      {!data && (
        <div
          style={{
            height: 500,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
