import { Button, Chip, TableCell, TableRow, Tooltip } from '@mui/material'
import moment from 'moment-timezone'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { requiresCredential } from 'siteline-common-all'
import { colors, makeStylesFast, toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { CredentialsIcon, DisableIcon, MappingsIcon } from '../../../common/components/Icons'
import { IdentifierIconButton } from '../../../common/components/IdentifierRow'
import {
  Contract,
  IntegrationProperties,
  useDeleteIntegrationMutation,
} from '../../../common/graphql/apollo-operations'
import { ContractForDetails } from '../ContractDetails'
import { IntegrationMappingsDialog } from './IntegrationMappingsDialog'

const useStyles = makeStylesFast((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))

type ContractDetailsIntegrationRowProps = {
  contract: ContractForDetails
  integration: IntegrationProperties
}

export function ContractDetailsIntegrationRow({
  contract,
  integration,
}: ContractDetailsIntegrationRowProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()
  const [mappingsDialogOpen, setMappingsDialogOpen] = useState<boolean>(false)
  const [deleteIntegration] = useDeleteIntegrationMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }
      cache.modify<WritableDeep<Contract>>({
        id: cache.identify(contract),
        fields: {
          integrations(existingIntegrations, { readField, toReference }) {
            const refs = toReferences(existingIntegrations, toReference)
            return refs.filter(
              (existing) => readField('id', existing) !== data.deleteIntegration.id
            )
          },
        },
      })
    },
  })

  const handleDelete = useCallback(() => {
    if (!window.confirm('Are you sure you want to delete this integration?')) {
      return
    }
    snackbar.showLoading()
    deleteIntegration({
      variables: { id: integration.id },
    })
      .then(() => snackbar.showSuccess())
      .catch((err: Error) => snackbar.showError(err.message))
  }, [deleteIntegration, integration.id, snackbar])

  let statusText: string
  let statusColor: string
  let tooltipTitle: string

  // Integration disabled
  if (!integration.isActive) {
    statusText = 'Disabled'
    statusColor = colors.orange30
    tooltipTitle = `Disabled at the company level`

    // Integration enabled, but missing credentials
  } else if (!integration.credentialsUpdatedAt && requiresCredential(integration.type)) {
    statusText = 'Credentials not set'
    statusColor = colors.red30
    tooltipTitle = 'Credentials were never set for this integration. All imports/exports will fail.'

    // Integration enabled and credentials set
  } else if (integration.credentialsUpdatedAt) {
    statusText = 'Enabled'
    statusColor = colors.green30
    tooltipTitle = `Credentials updated on ${moment
      .utc(integration.credentialsUpdatedAt)
      .format('MMM DD, YYYY')}`

    // Integration enabled and credentials not set (but they're not required)
  } else {
    statusText = 'Enabled'
    statusColor = colors.green30
    tooltipTitle = `Enabled on ${moment.utc(integration.createdAt).format('MMM DD, YYYY')}`
  }

  return (
    <>
      <TableRow key={integration.id}>
        <TableCell>{integration.longName}</TableCell>
        <TableCell>
          <Tooltip title={tooltipTitle} placement="top">
            <Chip label={statusText} sx={{ backgroundColor: statusColor }} size="small"></Chip>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.actions}>
          <Button size="small" startIcon={<DisableIcon />} color="primary" onClick={handleDelete}>
            Delete
          </Button>
          {requiresCredential(integration.type) && (
            <Button
              size="small"
              startIcon={<CredentialsIcon />}
              onClick={() => navigate(`/companies/${contract.company.id}`)}
            >
              Manage credentials
            </Button>
          )}
          <Button
            size="small"
            startIcon={<MappingsIcon />}
            color="primary"
            onClick={() => setMappingsDialogOpen(true)}
          >
            Edit mappings
          </Button>
          <IdentifierIconButton size="small" id={integration.id} />
        </TableCell>
      </TableRow>
      <IntegrationMappingsDialog
        integration={integration}
        open={mappingsDialogOpen}
        onClose={() => setMappingsDialogOpen(false)}
      />
    </>
  )
}
