import { createTheme, Theme, ThemeOptions } from '@mui/material'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const baseOptions: ThemeOptions = {
  palette: {
    background: {
      default: '#fafafa',
    },
  },
}

export const prodTheme = createTheme(baseOptions)
export const devTheme = createTheme({
  ...baseOptions,
  ...{
    palette: {
      ...baseOptions.palette,
      primary: {
        main: '#045833', // Green70
      },
      secondary: {
        main: '#5f7586', // Grey50
      },
    },
  },
})
