import { Alert, Button, Checkbox, TableCell, TableRow, TextField } from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { EditableCardCell } from '../../common/components/EditableCardRow'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import {
  useTemplateForAutocompleteQuery,
  useUpdateIntegrationMappingsMutation,
} from '../../common/graphql/apollo-operations'
import { TexturaIntegration } from '../../common/util/Integration'
import { LegalRequirementData } from '../contract-create/ContractCreate'

type LegalRequirementRowReadOnlyProps = {
  requirement: LegalRequirementData
  texturaIntegration: TexturaIntegration | null
  timeZone: string
}

/**
 * A single row in the list of compliance documents for a contract, when in read-only mode.
 */
export function LegalRequirementRowReadOnly({
  requirement,
  texturaIntegration,
  timeZone,
}: LegalRequirementRowReadOnlyProps) {
  const [updateMappings] = useUpdateIntegrationMappingsMutation()
  const texturaLegalRequirement = useCallback(
    (legalRequirementId?: string) => {
      if (!legalRequirementId || !texturaIntegration) {
        return
      }
      return texturaIntegration.mappings.legalDocumentRequirements.find(
        (req) => req.sitelineLegalRequirementId === legalRequirementId
      )
    },
    [texturaIntegration]
  )

  const { data: templateData } = useTemplateForAutocompleteQuery({
    variables: {
      id: requirement.formTemplateId ?? '',
    },
    skip: !requirement.formTemplateId,
  })

  return (
    <TableRow>
      <TableCell>{requirement.name}</TableCell>
      <TableCell>{requirement.expirationFrequency}</TableCell>
      {texturaIntegration && (
        <>
          {texturaLegalRequirement(requirement.id) ? (
            <EditableCardCell
              value={texturaLegalRequirement(requirement.id)?.isRecurring ?? false}
              formatValue={(value) => (value ? 'YES' : 'NO')}
              editComponent={(value, setValue) => (
                <Checkbox
                  checked={value}
                  onChange={(ev) => setValue(ev.target.checked)}
                  size="small"
                />
              )}
              readOnly={false}
              variables={(value) => {
                const newMappings = _.cloneDeep(texturaIntegration.mappings)
                const found = newMappings.legalDocumentRequirements.find(
                  (req) => req.sitelineLegalRequirementId === requirement.id
                )
                if (!found) {
                  throw new Error('Could not find requirement mapping')
                }
                found.isRecurring = value
                return {
                  input: {
                    integrationId: texturaIntegration.id,
                    mappings: newMappings as Record<string, unknown>,
                  },
                }
              }}
              mutate={updateMappings}
            />
          ) : (
            <TableCell>
              <Alert severity="error">Missing legal requirement info, refresh Textura data.</Alert>
            </TableCell>
          )}
        </>
      )}
      <TableCell>
        {requirement.startDate
          ? moment.tz(requirement.startDate, timeZone).format('MMM DD, YYYY')
          : ''}
      </TableCell>
      <TableCell>
        {requirement.endDate ? moment.tz(requirement.endDate, timeZone).format('MMM DD, YYYY') : ''}
      </TableCell>
      <TableCell>{templateData?.formTemplate.userVisibleName ?? ''}</TableCell>
      {texturaIntegration && (
        <>
          {texturaLegalRequirement(requirement.id) ? (
            <EditableCardCell
              value={
                texturaLegalRequirement(requirement.id)?.texturaLegalDocumentRequirementId ?? ''
              }
              formatValue={(value) => value}
              editComponent={(value, setValue) => (
                <TextField
                  value={value}
                  onChange={(ev) => setValue(ev.target.value)}
                  size="small"
                />
              )}
              readOnly={false}
              variables={(value) => {
                const newMappings = _.cloneDeep(texturaIntegration.mappings)
                const found = newMappings.legalDocumentRequirements.find(
                  (req) => req.sitelineLegalRequirementId === requirement.id
                )
                if (!found) {
                  throw new Error('Could not find requirement mapping')
                }
                found.texturaLegalDocumentRequirementId = value
                return {
                  input: {
                    integrationId: texturaIntegration.id,
                    mappings: newMappings as Record<string, unknown>,
                  },
                }
              }}
              mutate={updateMappings}
            />
          ) : (
            <TableCell>
              <Alert severity="error">Missing legal requirement info, refresh Textura data.</Alert>
            </TableCell>
          )}
        </>
      )}
      <TableCell>
        {requirement.formTemplateId && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            component={RouterLink}
            to={`/templates/${requirement.formTemplateId}`}
          >
            View Form Template
          </Button>
        )}
        <Button
          color="primary"
          variant="outlined"
          size="small"
          component={RouterLink}
          to={`/legalDocuments/${requirement.id}`}
        >
          View Documents
        </Button>
        {requirement.id && <IdentifierIconButton id={requirement.id} />}
      </TableCell>
    </TableRow>
  )
}
