import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { useCallback } from 'react'
import { colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  BillingType,
  FormTemplateType,
  PayAppRequirementCondition,
  UpdatePayAppRequirementGroupInput,
  UpdatePayAppRequirementInput,
  useTemplateVariantForAutocompleteLazyQuery,
} from '../../common/graphql/apollo-operations'
import { findInvalidConditions, hasSupersetConditions } from '../../common/util/PayAppRequirements'
import { PayAppRequirementGroup } from './PayAppRequirementGroup'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
    '& .MuiTableHead-root': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
    '& .MuiTableBody-root': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
  },
  footerRow: {
    border: 'none',
  },
}))

export const USER_FRIENDLY_CONDITION_NAMES = {
  [PayAppRequirementCondition.PROGRESS_ONLY]: 'On progress pay apps',
  [PayAppRequirementCondition.RETENTION_ONLY]: 'On retention pay apps',
  [PayAppRequirementCondition.NO_PROGRESS_REMAINING]: 'Progress 100% billed',
  [PayAppRequirementCondition.SOME_PROGRESS_REMAINING]: 'Progress NOT 100% billed',
  [PayAppRequirementCondition.NO_RETENTION_HELD]: '$0 retention held',
  [PayAppRequirementCondition.SOME_RETENTION_HELD]: '>$0 Retention held',
  [PayAppRequirementCondition.STORED_MATERIALS_BILLED]: 'Stored materials billed',
}

// Requirement input with a key that allows stable re-renders.
// Key is set to requirement ID, or uuidv4() in case of a new requirement
export type PayAppRequirementWithKey = UpdatePayAppRequirementInput & {
  key: string
}

// Group input with a key that allows stable re-renders.
// Key is set to group ID, or uuidv4() in case of a new group
export type PayAppRequirementGroupWithKey = Omit<
  UpdatePayAppRequirementGroupInput,
  'requirements'
> & {
  key: string
  requirements: PayAppRequirementWithKey[]
}

interface PayAppRequirementGroupsProps {
  billingType: BillingType
  payAppRequirementGroups: PayAppRequirementGroupWithKey[]
  setPayAppRequirementGroups: (newGroups: PayAppRequirementGroupWithKey[]) => void
  isEditing: boolean
  save: () => Promise<void>
  onCancel: () => void
  title?: string
}

export default function PayAppRequirementGroups({
  billingType,
  payAppRequirementGroups,
  setPayAppRequirementGroups,
  isEditing,
  save,
  onCancel,
  title = 'Pay App Requirement Groups',
}: PayAppRequirementGroupsProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [getVariant] = useTemplateVariantForAutocompleteLazyQuery()

  // Determine template type to show
  let templateType: FormTemplateType
  switch (billingType) {
    case BillingType.UNIT_PRICE:
      templateType = FormTemplateType.PAY_APP_UNIT_PRICE
      break
    case BillingType.LUMP_SUM:
      templateType = FormTemplateType.PAY_APP_LUMP_SUM
      break
    case BillingType.TIME_AND_MATERIALS:
      templateType = FormTemplateType.PAY_APP_TIME_AND_MATERIALS
      break
    case BillingType.QUICK:
      templateType = FormTemplateType.PAY_APP_QUICK
      break
  }

  const getVariantName = useCallback(
    async (id: string | null): Promise<string> => {
      if (!id) {
        return ''
      }
      const template = await getVariant({
        variables: { id },
      })
      return template.data?.formTemplateVariant.userVisibleName ?? ''
    },
    [getVariant]
  )

  const updateGroup = (group: PayAppRequirementGroupWithKey) => {
    const index = _.findIndex(payAppRequirementGroups, (g) => g.key === group.key)
    if (index === -1) {
      throw new Error('Could not find group to update')
    }
    const newGroups = [...payAppRequirementGroups]
    newGroups[index] = group
    setPayAppRequirementGroups(newGroups)
  }

  const removeGroup = (group: PayAppRequirementGroupWithKey) => {
    setPayAppRequirementGroups(_.without(payAppRequirementGroups, group))
  }

  const addGroup = () => {
    setPayAppRequirementGroups([
      ...payAppRequirementGroups,
      {
        key: uuidv4(),
        order: payAppRequirementGroups.length + 1,
        requirements: [
          {
            key: uuidv4(),
            templateVariantId: null,
            groupOrder: 1,
            conditions: [],
          },
        ],
      },
    ])
  }

  const onSave = async () => {
    // Check that all the pay app requirement groups are valid
    for (const group of payAppRequirementGroups) {
      const sortedRequirements = _.sortBy(
        group.requirements,
        (requirement) => requirement.groupOrder
      )
      // Find if any pay app requirement other than the failback has no conditions. If so, fail.
      const missingConditions = sortedRequirements.find((requirement, index) => {
        if (index === sortedRequirements.length - 1) {
          return false
        }
        return requirement.conditions.length === 0
      })
      if (missingConditions) {
        const templateName = await getVariantName(missingConditions.templateVariantId ?? null)
        snackbar.showError(`${templateName} must have conditions since it is not the fallback.`)
        return
      }

      // Find if an earlier pay app requirement has a strict subset of conditions. If so, fail.
      for (const requirement of sortedRequirements) {
        const index = _.indexOf(sortedRequirements, requirement)
        const supersetConditions = hasSupersetConditions(
          requirement,
          sortedRequirements.slice(0, index)
        )
        if (!supersetConditions) {
          continue
        }
        const supersetTemplateName = await getVariantName(
          supersetConditions.templateVariantId ?? null
        )
        const templateName = await getVariantName(requirement.templateVariantId ?? null)

        snackbar.showError(
          `${templateName} cannot have a superset of ${supersetTemplateName}'s conditions since it is later in the list.`
        )
        return
      }

      // Find if any pay app requirement has conflicting conditions. If so, fail.
      for (const requirement of sortedRequirements) {
        const invalidConditions = findInvalidConditions([...requirement.conditions])
        if (!invalidConditions) {
          continue
        }

        const templateName = await getVariantName(requirement.templateVariantId ?? null)
        const names = invalidConditions
          .map((condition) => USER_FRIENDLY_CONDITION_NAMES[condition])
          .join(', ')

        snackbar.showError(`${templateName} has incompatible conditions ${names}`)
        return
      }
    }
    await save()
  }

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Button color="primary" onClick={() => onCancel()}>
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        }
      />
      <CardContent className={classes.cardContent}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell style={{ width: '10%' }}>Order</TableCell>
              <TableCell style={{ width: '30%' }}>Conditions</TableCell>
              <TableCell>Template variant</TableCell>
              <TableCell>Template Status</TableCell>
              <TableCell style={{ width: 135 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {payAppRequirementGroups.map((requirementGroup, index) => (
            <PayAppRequirementGroup
              key={requirementGroup.key}
              payAppRequirementGroup={requirementGroup}
              setPayAppRequirementGroup={updateGroup}
              removePayAppRequirementGroup={() => removeGroup(requirementGroup)}
              isEditing={isEditing}
              templateType={templateType}
              groupIndex={index}
            />
          ))}
          {isEditing && (
            <TableFooter>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2} className={classes.footerRow}>
                  <Button startIcon={<AddIcon />} size="small" onClick={() => addGroup()}>
                    Add Pay App Requirement Group
                  </Button>
                </TableCell>
                <TableCell colSpan={1}></TableCell>
                <TableCell align="right">
                  <Button color="primary" variant="contained" onClick={onSave}>
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </CardContent>
    </Card>
  )
}
