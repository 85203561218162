import { integrationTypes } from 'siteline-common-all'
import * as fragments from '../../common/graphql/Fragments'
import { IntegrationProperties, IntegrationType } from '../graphql/apollo-operations'

export type ContractWithIntegrations = Pick<fragments.Contract, 'integrations'>

export type TexturaIntegration = IntegrationProperties & {
  type: IntegrationType.TEXTURA
  mappings: integrationTypes.IntegrationMappingsTextura
}

export type GcPayIntegration = IntegrationProperties & {
  type: IntegrationType.GC_PAY
  mappings: integrationTypes.IntegrationMappingsGcPay
}

export type Sage100ContractorIntegration = IntegrationProperties & {
  type: IntegrationType.SAGE_100_CONTRACTOR
  mappings: integrationTypes.IntegrationMappingsSage100Contractor
}

export type Sage300CreIntegration = IntegrationProperties & {
  type: IntegrationType.SAGE_300_CRE
  mappings: integrationTypes.IntegrationMappingsSage300Cre
}

export type TestIntegration = IntegrationProperties & {
  type: IntegrationType.TEST
  mappings: integrationTypes.IntegrationMappingsTest
}

export function hasIntegration(contract: ContractWithIntegrations, type: IntegrationType): boolean {
  const integration = getIntegration(contract, type)
  return integration !== null
}

export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType.TEXTURA
): TexturaIntegration | null
export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType.GC_PAY
): GcPayIntegration | null
export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType.SAGE_100_CONTRACTOR
): Sage100ContractorIntegration | null
export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType.SAGE_300_CRE
): Sage300CreIntegration | null
export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType.TEST
): TestIntegration | null
export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType
): IntegrationProperties | null
export function getIntegration(
  contract: ContractWithIntegrations,
  type: IntegrationType
): IntegrationProperties | null {
  const found = contract.integrations.find((integration) => integration.type === type)
  return found ?? null
}
