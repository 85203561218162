import { gql } from '@apollo/client'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button, Chip, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material'
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import {
  isAgaveIntegration,
  isHh2Integration,
  requiresCredential,
  supportsAgaveDebuggerLink,
  supportsCredentialsAutoRotation,
} from 'siteline-common-all'
import { colors, useSitelineSnackbar } from 'siteline-common-web'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyIntegrationProperties,
  useAgaveDebuggerSessionUrlLazyQuery,
  useArchiveCompanyIntegrationMutation,
  useUnarchiveCompanyIntegrationMutation,
} from '../../common/graphql/apollo-operations'
import { AddOrUpdateCompanyIntegrationModal } from './AddOrUpdateCompanyIntegrationModal'

gql`
  mutation archiveCompanyIntegration($id: ID!) {
    archiveCompanyIntegration(id: $id) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  mutation unarchiveCompanyIntegration($id: ID!) {
    unarchiveCompanyIntegration(id: $id) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

type CompanyIntegrationRowProps = {
  company: fragments.DetailedCompany
  integration: CompanyIntegrationProperties
  onUpdateCredentials: () => void
  onRotateCredentials: () => void
  onExportCredentials: () => void
  onEnableAutoRotation: () => void
}

/** A single row in the list of company integrations **/
export function CompanyIntegrationRow({
  company,
  integration,
  onUpdateCredentials,
  onRotateCredentials,
  onEnableAutoRotation,
  onExportCredentials,
}: CompanyIntegrationRowProps) {
  const [archiveIntegration] = useArchiveCompanyIntegrationMutation()
  const [unarchiveIntegration] = useUnarchiveCompanyIntegrationMutation()
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)
  const snackbar = useSitelineSnackbar()
  const popupState = usePopupState({ variant: 'popover', popupId: 'formActions' })

  const handleArchive = useCallback(
    (integration: CompanyIntegrationProperties) => {
      const confirmation = window.confirm(
        'Are you sure you want to disable this integration? Contracts with this integration will no longer be able to read/write to the 3rd-party.'
      )
      if (!confirmation) {
        return
      }

      archiveIntegration({
        variables: {
          id: integration.id,
        },
      })
        .then(() => snackbar.showSuccess('Disabled integration'))
        .catch((error) => snackbar.showError(error.message))
    },
    [archiveIntegration, snackbar]
  )

  const hasDebuggerLink = supportsAgaveDebuggerLink(integration.type)

  const [getDebuggerUrl] = useAgaveDebuggerSessionUrlLazyQuery()
  const openDebugger = useCallback(async () => {
    const { data } = await getDebuggerUrl({
      variables: { input: { companyIntegrationId: integration.id } },
    })
    if (data?.agaveDebuggerSessionUrl) {
      window.open(data.agaveDebuggerSessionUrl)
    }
  }, [integration, getDebuggerUrl])

  const handleUnarchive = useCallback(
    (integration: CompanyIntegrationProperties) => {
      const confirmation = window.confirm(
        'Are you sure you want to re-enable this integration? Contracts with this integration will be able to read/write to the 3rd-party again.'
      )
      if (!confirmation) {
        return
      }

      unarchiveIntegration({
        variables: {
          id: integration.id,
        },
      })
        .then(() => snackbar.showSuccess('Enabled integration'))
        .catch((error) => snackbar.showError(error.message))
    },
    [unarchiveIntegration, snackbar]
  )

  let statusText: string
  let statusColor: string
  let tooltipTitle: string

  const canUpdateLabel = useMemo(() => !integration.archivedAt, [integration.archivedAt])
  const canUpdateCredentials = useMemo(() => !integration.archivedAt, [integration])
  const canEnableAutoRotation = useMemo(
    () =>
      !integration.archivedAt &&
      !integration.credentialsReadyForAutoRotation &&
      supportsCredentialsAutoRotation(integration.type),
    [integration]
  )
  const canRotateCredentials = useMemo(
    () =>
      !integration.archivedAt &&
      integration.credentialsReadyForAutoRotation &&
      supportsCredentialsAutoRotation(integration.type),
    [integration]
  )
  const canExportCredentialsToPostman = useMemo(
    () =>
      !integration.archivedAt &&
      integration.credentialsUpdatedAt &&
      (isAgaveIntegration(integration.type) || isHh2Integration(integration.type)),
    [integration]
  )
  const canDisable = useMemo(() => !integration.archivedAt, [integration.archivedAt])
  const canEnable = useMemo(() => integration.archivedAt, [integration.archivedAt])

  // Integration disabled
  if (integration.archivedAt) {
    statusText = 'Disabled'
    statusColor = colors.grey30
    tooltipTitle = `Disabled on ${moment.utc(integration.archivedAt).format('MMM DD, YYYY')}`

    // Integration enabled, but missing credentials
  } else if (!integration.credentialsUpdatedAt && requiresCredential(integration.type)) {
    statusText = 'Credentials not set'
    statusColor = colors.red30
    tooltipTitle = 'Credentials were never set for this integration. All imports/exports will fail.'

    // Credentials set, but not ready for auto-rotation (Textura credentials not linked to 1Password)
  } else if (
    integration.credentialsUpdatedAt &&
    supportsCredentialsAutoRotation(integration.type) &&
    !integration.credentialsReadyForAutoRotation
  ) {
    statusText = 'Missing auto-rotation settings'
    statusColor = colors.orange30
    tooltipTitle = `Credentials are not linked to 1Password and cannot be automatically rotated`

    // Integration enabled and credentials set
  } else if (integration.credentialsUpdatedAt) {
    statusText = 'Enabled'
    statusColor = colors.green30
    tooltipTitle = `Credentials updated on ${moment
      .utc(integration.credentialsUpdatedAt)
      .format('MMM DD, YYYY')}`

    // Integration enabled and credentials not set (but they're not required)
  } else {
    statusText = 'Enabled'
    statusColor = colors.green30
    tooltipTitle = `Enabled on ${moment.utc(integration.createdAt).format('MMM DD, YYYY')}`
  }

  return (
    <>
      <TableRow key={integration.id}>
        <TableCell>{integration.longName}</TableCell>
        <TableCell>
          <Tooltip title={tooltipTitle} placement="top">
            <Chip label={statusText} sx={{ backgroundColor: statusColor }} size="small"></Chip>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
            {...bindToggle(popupState)}
          >
            Actions
          </Button>
          <Menu {...bindMenu(popupState)}>
            {canUpdateLabel && (
              <MenuItem onClick={() => setIsUpdateModalOpen(true)}>
                Update integration metadata
              </MenuItem>
            )}
            {canUpdateCredentials && (
              <MenuItem onClick={() => onUpdateCredentials()}>
                Set credentials
                {supportsCredentialsAutoRotation(integration.type) ? ' manually' : ''}
              </MenuItem>
            )}
            {canEnableAutoRotation && (
              <MenuItem onClick={() => onEnableAutoRotation()}>
                Enable credentials auto-rotation
              </MenuItem>
            )}
            {canRotateCredentials && (
              <MenuItem onClick={() => onRotateCredentials()}>Rotate credentials</MenuItem>
            )}
            {canExportCredentialsToPostman && (
              <MenuItem onClick={() => onExportCredentials()}>
                Export credentials to Postman
              </MenuItem>
            )}
            {hasDebuggerLink && (
              <MenuItem onClick={() => openDebugger()}>Open Agave debugger</MenuItem>
            )}
            {canDisable && (
              <MenuItem onClick={() => handleArchive(integration)} style={{ color: '#ff0000' }}>
                Disable integration
              </MenuItem>
            )}
            {canEnable && (
              <MenuItem onClick={() => handleUnarchive(integration)}>
                Re-enable integration
              </MenuItem>
            )}
          </Menu>
          <IdentifierIconButton id={integration.id} />
        </TableCell>
      </TableRow>
      <AddOrUpdateCompanyIntegrationModal
        open={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        company={company}
        companyIntegration={integration}
      />
    </>
  )
}
