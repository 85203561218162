import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import PersonIcon from '@mui/icons-material/Person'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { percentToDecimal } from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { CompanyAutocomplete } from '../../common/components/CompanyAutocomplete'
import {
  ContractProperties,
  RetentionTrackingLevel,
  UserProperties,
  UserStatus,
  useAddContractMutation,
  useGetCompanyForContractCreationLazyQuery,
  useUpdateContractMutation,
  useUpdateRoundRetentionMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  amount: {
    width: 170,
  },
  save: {
    display: 'flex',
    margin: theme.spacing(1),
    float: 'right',
  },
  contractsCards: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    '& .companyCard': {
      width: '33%',
    },
    '& .contractCard': {
      width: '67%',
      '& .retentionTracking': {
        '& label': {
          margin: theme.spacing(0, 0, 2, 0),
          '& .MuiInput-root': {
            marginRight: theme.spacing(1),
          },
        },
      },
    },
  },
}))

type ContractCreateMetadataProps = {
  projectId: string
  // This id is supplied when the user is picking a company
  companyId: string | null
  setCompanyId: (newId: string | null) => void
  contract: ContractProperties | undefined
  setContract: (newContract: ContractProperties) => void
  onCreate: (contractId: string) => void
}

export function ContractCreateMetadata({
  projectId,
  companyId,
  setCompanyId,
  contract,
  setContract,
  onCreate,
}: ContractCreateMetadataProps) {
  const classes = useStyles()

  const [retentionTrackingLevel, setRetentionTrackingLevel] = useState<RetentionTrackingLevel>(
    RetentionTrackingLevel.STANDARD
  )

  const [daysBeforePayAppDue, setDaysBeforePayAppDue] = useState(3) // Default days before
  const [pastPayAppCount, setPastPayAppCount] = useState(0) // Default to 0 for new projects

  const snackbar = useSitelineSnackbar()
  const [addContractMutation] = useAddContractMutation()
  const [updateRoundRetention] = useUpdateRoundRetentionMutation()
  const [updateContractMutation] = useUpdateContractMutation()
  const [internalProjectNumber, setInternalProjectNumber] = useState('')
  const [vendorNumber, setVendorNumber] = useState('')

  const [roundRetention, setRoundRetention] = useState(false)
  const [retentionPercent, setRetentionPercent] = useState(10)

  const [users, setUsers] = useState<UserProperties[]>([])
  const [deletedUsers, setDeletedUsers] = useState<UserProperties[]>([])

  const [isEditing, setIsEditing] = useState(true)

  const [getCompany, { data: companyData }] = useGetCompanyForContractCreationLazyQuery()
  const company = companyData?.company

  useEffect(() => {
    setUsers(
      (company?.users ?? [])
        .filter((companyUser) => companyUser.status === UserStatus.ACTIVE)
        .map((companyUser) => companyUser.user)
    )
    setDeletedUsers([])
  }, [company])

  const setCompany = useCallback(
    (id: string | null) => {
      if (!id) {
        setCompanyId(null)
        return
      }
      getCompany({
        variables: {
          companyId: id,
        },
      })
      setCompanyId(id)
    },
    [getCompany, setCompanyId]
  )

  useEffect(() => {
    if (companyId) {
      setCompany(companyId)
    }
  }, [companyId, setCompany])

  const validateAndSubmit = async () => {
    if (!company) {
      throw new Error('Subcontractor company must be provided')
    }

    // Validate that `retentionPercent` (assigned to `retentionPercent`) is between 0-100%
    if (retentionPercent < 0 || retentionPercent > 100) {
      throw new Error('Initial pay app retention % must be between 0% and 100%')
    }

    if (!contract) {
      // Add metadata the first time
      const response = await addContractMutation({
        variables: {
          input: {
            projectId,
            companyId: company.id,
            retentionTrackingLevel,
            userIds: users.map((user) => user.id),
            daysBeforePayAppDue,
            pastPayAppCount,
            internalProjectNumber: internalProjectNumber.length > 0 ? internalProjectNumber : null,
            vendorNumber: vendorNumber.length > 0 ? vendorNumber : null,
            roundRetention,
            defaultRetentionPercent: percentToDecimal(retentionPercent),
          },
        },
      })
      if (!response.data) {
        throw new Error('No data in response')
      }
      return response.data.addContract
    } else {
      // Update existing contract after the first time
      const response = await updateContractMutation({
        variables: {
          input: {
            id: contract.id,
            retentionTrackingLevel,
            daysBeforePayAppDue,
            pastPayAppCount,
            internalProjectNumber: internalProjectNumber.length > 0 ? internalProjectNumber : null,
            vendorNumber: vendorNumber.length > 0 ? vendorNumber : null,
          },
        },
      })
      if (roundRetention !== contract.roundRetention) {
        await updateRoundRetention({
          variables: {
            input: {
              contractId: contract.id,
              roundRetention,
            },
          },
        })
      }

      if (!response.data) {
        throw new Error('No data in response')
      }
      return response.data.updateContract
    }
  }

  const removeUser = (index: number) => {
    const newUsers = [...users]
    const deletedUser = newUsers.splice(index, 1)
    const newDeletedUsers = [...deletedUsers, ...deletedUser]
    setUsers(newUsers)
    setDeletedUsers(newDeletedUsers)
  }

  const addBackUser = (index: number) => {
    const newDeletedUsers = [...deletedUsers]
    const newUser = newDeletedUsers.splice(index, 1)
    const newUsers = [...users, ...newUser]
    setUsers(newUsers)
    setDeletedUsers(newDeletedUsers)
  }

  const onSubmit = (ev: React.FormEvent): void => {
    ev.preventDefault()
    validateAndSubmit()
      .then((metadata) => {
        setContract(metadata)
        setIsEditing(false)
        onCreate(metadata.id)
      })
      .catch((err: Error) => {
        snackbar.showError(err.message)
      })
  }

  const onCancel = () => {
    if (isEditing) {
      setRoundRetention(contract?.roundRetention ?? false)
      setDaysBeforePayAppDue(contract?.daysBeforePayAppDue ?? 3)
      setPastPayAppCount(contract?.pastPayAppCount ?? 0)
      setInternalProjectNumber(contract?.internalProjectNumber ?? '')
      setVendorNumber(contract?.vendorNumber ?? '')
    }
    setIsEditing(!isEditing)
  }

  const retentionLevelOptions = Object.values(RetentionTrackingLevel).filter(
    (level) => level !== RetentionTrackingLevel.NONE
  )

  return (
    <div className={classes.contractsCards}>
      <Card className="companyCard">
        <CardHeader title="Company information" />
        <CardContent>
          {/* Company */}
          {contract && company && <Typography variant="h6">{company.name}</Typography>}
          {!contract && (
            <>
              <CompanyAutocomplete
                required
                fullWidth
                label="Company"
                className={classes.input}
                companyId={company?.id ?? null}
                setCompanyId={setCompany}
              />
              <List
                dense
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    The following users will be added to the project
                  </ListSubheader>
                }
              >
                {users.map((user, index) => (
                  <ListItem key={user.id}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${user.firstName} ${user.lastName} (${user.email})`} />
                    <IconButton onClick={() => removeUser(index)} size="large">
                      <ClearIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              {!!deletedUsers.length && (
                <List
                  dense
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      The following users will NOT be added to the project
                    </ListSubheader>
                  }
                >
                  {deletedUsers.map((user, index) => (
                    <ListItem key={user.id}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                      <IconButton onClick={() => addBackUser(index)} size="large">
                        <AddIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
          {contract && (
            <List
              dense
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Project Users
                </ListSubheader>
              }
            >
              {users.map((user) => (
                <ListItem key={user.id}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
      <Card className="contractCard">
        <CardHeader
          title="Contract"
          action={
            <Button color="primary" onClick={() => onCancel()}>
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          }
        />
        <CardContent>
          {/* Round retention */}
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name="round-retention"
                  value={roundRetention}
                  checked={roundRetention}
                  onChange={(ev, checked) => setRoundRetention(checked)}
                />
              }
              label="Round retention to nearest dollar"
              disabled={!isEditing}
            />
          </div>

          {/* Retention Tracking Level */}
          <div className="retentionTracking">
            <FormControlLabel
              control={
                <Select
                  variant="standard"
                  required
                  value={retentionTrackingLevel}
                  onChange={(ev) =>
                    setRetentionTrackingLevel(ev.target.value as RetentionTrackingLevel)
                  }
                >
                  {retentionLevelOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              }
              label="Retention tracking level"
              disabled={!isEditing}
            />
          </div>

          <TextField
            required
            fullWidth
            type="number"
            label="Initial Pay App Retention %"
            className={classes.input}
            value={retentionPercent}
            onChange={(ev) => setRetentionPercent(Number(ev.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!isEditing}
          />

          {/* Days before PayApp is due */}
          <TextField
            required
            fullWidth
            label="Days before PayApp is due (used to remind PMs as internal deadline)"
            className={classes.input}
            value={daysBeforePayAppDue}
            onChange={(ev) => setDaysBeforePayAppDue(Number(ev.target.value))}
            disabled={!isEditing}
          />

          {/* Number of pay apps already completed for ongoing projects */}
          <TextField
            required
            fullWidth
            label="Number of pay apps already completed (for ongoing projects)"
            className={classes.input}
            value={pastPayAppCount}
            onChange={(ev) => setPastPayAppCount(Number(ev.target.value))}
            disabled={!isEditing}
          />

          {/* Internal project number */}
          <TextField
            fullWidth
            label="Internal Project Number"
            className={classes.input}
            value={internalProjectNumber}
            onChange={(ev) => setInternalProjectNumber(ev.target.value)}
            disabled={!isEditing}
          />

          {/* Vendor number */}
          <TextField
            fullWidth
            label="Vendor Number"
            className={classes.input}
            value={vendorNumber}
            onChange={(ev) => setVendorNumber(ev.target.value)}
            disabled={!isEditing}
          />
        </CardContent>
        {isEditing && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            className={classes.save}
          >
            Save
          </Button>
        )}
      </Card>
    </div>
  )
}
