import { gql } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import {
  isAgaveIntegration,
  isHh2Integration,
  supportsCredentialsAutoRotation,
} from 'siteline-common-all'
import { IntegrationType, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  CompanyIntegrationProperties,
  useExportIntegrationCredentialsToPostmanMutation,
  useRotateIntegrationCredentialMutation,
} from '../../common/graphql/apollo-operations'
import { AddOrUpdateCompanyIntegrationModal } from './AddOrUpdateCompanyIntegrationModal'
import { CompanyIntegrationRow } from './CompanyIntegrationRow'
import { UpdateAgaveCredentialModal } from './UpdateAgaveCredentialModal'
import { UpdateCredentialModal } from './UpdateCredentialModal'
import { UpdateHh2CredentialModal } from './UpdateHh2CredentialModal'
import { UpdateTexturaCredentialModal } from './UpdateTexturaCredentialModal'

gql`
  mutation updateIntegrationCredential($input: UpdateIntegrationCredentialInput!) {
    updateIntegrationCredential(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  mutation exportIntegrationCredentialsToPostman(
    $input: ExportIntegrationCredentialsToPostmanInput!
  ) {
    exportIntegrationCredentialsToPostman(input: $input)
  }
`

gql`
  mutation rotateIntegrationCredential($input: RotateIntegrationCredentialInput!) {
    rotateIntegrationCredential(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  query companyForCredentials($id: ID!) {
    company(id: $id) {
      id
      companyIntegrations {
        ...CompanyIntegrationProperties
      }
    }
  }
  ${fragments.companyIntegration}
`
gql`
  query agaveDebuggerSessionUrl($input: AgaveDebuggerSessionUrlInput!) {
    agaveDebuggerSessionUrl(input: $input)
  }
`

/** Manage integration credentials for the company. */
export function CompanyIntegrations({ company }: { company: DetailedCompany }) {
  const snackbar = useSitelineSnackbar()
  const [addIntegrationModalOpen, setAddIntegrationModalOpen] = useState<boolean>(false)
  const [updateCredentialOpen, setUpdateCredentialOpen] = useState<boolean>(false)
  const [enableAutoRotationModalOpen, setEnableAutoRotationModalOpen] = useState<boolean>(false)
  const [updatingIntegration, setUpdatingIntegration] =
    useState<CompanyIntegrationProperties | null>(null)
  const [rotateCredentials] = useRotateIntegrationCredentialMutation()
  const [exportCredentials] = useExportIntegrationCredentialsToPostmanMutation()

  const supportsGenericAuth = useMemo(() => {
    if (!updatingIntegration) {
      return false
    }
    switch (updatingIntegration.type) {
      case IntegrationType.ACUMATICA:
      case IntegrationType.SPECTRUM:
      case IntegrationType.VISTA:
      case IntegrationType.PROCORE:
      case IntegrationType.FOUNDATION:
      case IntegrationType.SAGE_INTACCT:
        return false
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.COMPUTER_EASE_FILE:
      case IntegrationType.GC_PAY:
      case IntegrationType.TEXTURA:
      case IntegrationType.TEST:
      case IntegrationType.SAGE_100_CONTRACTOR:
      case IntegrationType.SAGE_300_CRE:
        return true
    }
  }, [updatingIntegration])

  const supportsAgaveAuth = useMemo(() => {
    if (!updatingIntegration) {
      return false
    }
    return isAgaveIntegration(updatingIntegration.type)
  }, [updatingIntegration])

  const supportsHh2Auth = useMemo(() => {
    if (!updatingIntegration) {
      return false
    }
    return isHh2Integration(updatingIntegration.type)
  }, [updatingIntegration])

  const sortedIntegrations = useMemo(() => {
    const collator = new Intl.Collator()
    return [...company.companyIntegrations].sort((a, b) => collator.compare(a.longName, b.longName))
  }, [company.companyIntegrations])

  const handleUpdateCredentials = useCallback((integration: CompanyIntegrationProperties) => {
    setUpdateCredentialOpen(true)
    setUpdatingIntegration(integration)
  }, [])

  const handleEnableAutoRotation = useCallback((integration: CompanyIntegrationProperties) => {
    setEnableAutoRotationModalOpen(true)
    setUpdatingIntegration(integration)
  }, [])

  const handleRotateCredentials = useCallback(
    (integration: CompanyIntegrationProperties) => {
      const confirmed = window.confirm(
        'Are you sure you want these credentials? This will rotate the password in the integration and in 1Password'
      )
      if (!confirmed) {
        return
      }
      snackbar.showLoading('Rotating credentials...')
      rotateCredentials({
        variables: {
          input: {
            companyIntegrationId: integration.id,
          },
        },
      })
        .then(() => snackbar.showSuccess('Credentials rotated'))
        .catch((err) => snackbar.showError(err.message))
    },
    [rotateCredentials, snackbar]
  )

  const handleExportCredentials = useCallback(
    (integration: CompanyIntegrationProperties) => {
      const confirmed = window.confirm(
        'Are you sure you want to export these credentials? This will override existing variables in Postman, or create new ones.'
      )
      if (!confirmed) {
        return
      }
      snackbar.showLoading('Exporting credentials...')
      exportCredentials({
        variables: {
          input: {
            companyIntegrationId: integration.id,
          },
        },
      })
        .then(() => snackbar.showSuccess('Credentials exported'))
        .catch((err) => snackbar.showError(err.message))
    },
    [exportCredentials, snackbar]
  )

  return (
    <Card>
      <CardHeader title="Integrations" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Integration</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedIntegrations.map((integration) => (
              <CompanyIntegrationRow
                key={integration.id}
                company={company}
                integration={integration}
                onUpdateCredentials={() => handleUpdateCredentials(integration)}
                onEnableAutoRotation={() => handleEnableAutoRotation(integration)}
                onRotateCredentials={() => handleRotateCredentials(integration)}
                onExportCredentials={() => handleExportCredentials(integration)}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Button startIcon={<AddIcon />} onClick={() => setAddIntegrationModalOpen(true)}>
          Add integration
        </Button>
      </CardActions>
      {updatingIntegration && supportsCredentialsAutoRotation(updatingIntegration.type) && (
        <UpdateTexturaCredentialModal
          companyIntegration={updatingIntegration}
          open={enableAutoRotationModalOpen}
          onClose={() => setEnableAutoRotationModalOpen(false)}
        />
      )}
      {updatingIntegration && supportsGenericAuth && (
        <UpdateCredentialModal
          companyIntegration={updatingIntegration}
          open={updateCredentialOpen}
          setOpen={setUpdateCredentialOpen}
        />
      )}
      {updatingIntegration && supportsHh2Auth && (
        <UpdateHh2CredentialModal
          companyIntegration={updatingIntegration}
          open={updateCredentialOpen}
          setOpen={setUpdateCredentialOpen}
        />
      )}
      {updatingIntegration && supportsAgaveAuth && (
        <UpdateAgaveCredentialModal
          companyId={company.id}
          companyIntegration={updatingIntegration}
          open={updateCredentialOpen}
          setOpen={setUpdateCredentialOpen}
        />
      )}
      <AddOrUpdateCompanyIntegrationModal
        open={addIntegrationModalOpen}
        onClose={() => setAddIntegrationModalOpen(false)}
        company={company}
        companyIntegration={null}
      />
    </Card>
  )
}
