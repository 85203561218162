import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MapIcon from '@mui/icons-material/Map'
import TimerIcon from '@mui/icons-material/Timer'
import { Button, Drawer, IconButton, List, ListItem, ListSubheader, Theme } from '@mui/material'
import { ReactNode, createContext, useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import {
  CachingIcon,
  DemoIcon,
  FormTemplatesIcon,
  GcDedupeIcon,
  HomeIcon,
  PendingSyncIcon,
  ReportingIcon,
  SyncIcon,
  TemplateSearchIcon,
  TemplateVariableIcon,
  TemplateVariableLookupIcon,
  UserCampaignIcon,
} from './Icons'

type NavBarContextType = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const NavBarContext = createContext<NavBarContextType>({
  isOpen: false,
  setIsOpen: (): void => undefined,
})

export function useNavBar() {
  return useContext(NavBarContext)
}

export function NavBarProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(true)
  const contextValue = { isOpen, setIsOpen }
  return <NavBarContext.Provider value={contextValue}>{children}</NavBarContext.Provider>
}

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonLeaf: {
    color: 'inherit',
    padding: theme.spacing(),
    marginLeft: theme.spacing(0.5),
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&.active': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  collapse: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
  },
}))

export default function NavBar({ onClose }: { onClose?: () => void }) {
  const classes = useStyles()

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: classes.drawer,
      }}
      open={onClose !== undefined}
      variant="persistent"
    >
      <List>
        <ListSubheader disableSticky>Pages</ListSubheader>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/"
            startIcon={<HomeIcon />}
          >
            Home
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/templates"
            startIcon={<FormTemplatesIcon />}
          >
            Forms
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/templates/match"
            startIcon={<TemplateSearchIcon />}
          >
            Form match
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/templateVariables"
            startIcon={<TemplateVariableIcon />}
          >
            Template variables
          </Button>
        </ListItem>

        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/templateVariables/lookup"
            startIcon={<TemplateVariableLookupIcon />}
          >
            Variable lookup
          </Button>
        </ListItem>

        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/caching"
            startIcon={<CachingIcon />}
          >
            Caching
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/reporting"
            startIcon={<ReportingIcon />}
          >
            Reporting
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/campaigns"
            startIcon={<UserCampaignIcon />}
          >
            User campaigns
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/writeSyncOperations"
            startIcon={<SyncIcon />}
          >
            Syncs
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/pendingSyncs"
            startIcon={<PendingSyncIcon />}
          >
            Pending syncs
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/customerLocationsMap"
            startIcon={<MapIcon />}
          >
            Customer locations map
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/gcDedupe"
            startIcon={<GcDedupeIcon />}
          >
            GC dedupe
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/formTurnaround"
            startIcon={<TimerIcon />}
          >
            Form turnaround
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/customerDashboards"
            startIcon={<DashboardIcon />}
          >
            Customer dashboard
          </Button>
        </ListItem>
        <ListItem className={classes.itemLeaf} disableGutters>
          <Button
            className={classes.buttonLeaf}
            component={NavLink}
            to="/demoTools"
            startIcon={<DemoIcon />}
          >
            Demo tools
          </Button>
        </ListItem>
      </List>
      <IconButton onClick={onClose} className={classes.collapse} size="large">
        <ChevronLeftIcon />
      </IconButton>
    </Drawer>
  )
}
