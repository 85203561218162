import { gql } from '@apollo/client'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { clsx } from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { makeStylesFast, savePdf, useSitelineSnackbar } from 'siteline-common-web'
import { getCurrentUserAuthorization } from '../../client'
import { CompanyAutocomplete } from '../../common/components/CompanyAutocomplete'
import Page from '../../common/components/Page'
import { reportingBaseUrl } from '../../common/config/constants'
import * as fragments from '../../common/graphql/Fragments'
import {
  EmailType,
  useAllProjectUsersByCompanyLazyQuery,
  useSendCompanyMonthlyReportTestEmailMutation,
  useSitelineTeamMembersQuery,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& .container': {
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .MuiCardHeader-root': {
        paddingBottom: 0,
      },
      '& .generate': {
        '& .heading': {
          marginRight: theme.spacing(1),
        },
        '& .row': {
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
          '& > *:first-child': {
            marginRight: theme.spacing(2),
          },
        },
      },
      '& .button': {
        marginTop: theme.spacing(2),
      },
      '& .recipients': {
        display: 'flex',
        flexDirection: 'column',
        '&.visible': {
          marginTop: theme.spacing(3),
          borderTop: `1px solid lightgrey`,
          paddingTop: theme.spacing(3),
        },
        '& .subtitle': {
          color: 'grey',
        },
        '& .marginTop': {
          marginTop: theme.spacing(2),
        },
        '& .blocked': {
          color: 'darkred',
        },
      },
    },
  },
  input: {
    width: 350,
  },
  sendReport: {
    '& .sendButton': {
      marginTop: theme.spacing(3),
    },
  },
}))

gql`
  query allProjectUsersByCompany($id: ID!) {
    allProjectUsersByCompany(id: $id) {
      ...CompanyUserProperties
      user {
        ...UserProperties
        blockedEmails
      }
    }
  }
  ${fragments.companyUser}
  ${fragments.user}
`

gql`
  mutation sendCompanyMonthlyReportTestEmail($input: SendCompanyMonthlyReportTestEmailInput!) {
    sendCompanyMonthlyReportTestEmail(input: $input) {
      id
      email
    }
  }
`

interface UserOption {
  id: string
  email: string
}

export default function Reporting() {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [loadingReport, setLoadingReport] = useState<boolean>(false)
  const [emailUser, setEmailUser] = useState<UserOption | null>(null)

  const [getRecipients, { data: companyUsers, loading: loadingRecipients }] =
    useAllProjectUsersByCompanyLazyQuery()
  const [sendCompanyMonthlyReportTestEmail, { loading: sendingEmail }] =
    useSendCompanyMonthlyReportTestEmailMutation()
  const { data: adminsData } = useSitelineTeamMembersQuery()
  const admins = _.orderBy(adminsData?.sitelineTeamMembers ?? [], (user) => user.firstName, ['asc'])

  const months = _.range(6).map((num) => moment.tz('America/Los_Angeles').subtract(num, 'months'))
  const [month, setMonth] = useState<moment.Moment>(months[0])

  const generateReport = async () => {
    if (!companyId) {
      snackbar.showError('Select a company to generate a report')
      return
    }
    setLoadingReport(true)
    const monthStr = month.month()
    const yearStr = month.year()
    const url = new URL(reportingBaseUrl)
    url.pathname = '/monthlyReport/pdf'
    url.searchParams.append('companyId', companyId)
    url.searchParams.append('month', monthStr.toString())
    url.searchParams.append('year', yearStr.toString())

    try {
      const pdf = await fetch(url.toString(), {
        headers: {
          authorization: await getCurrentUserAuthorization(),
        },
      })
      if (pdf.status !== 200) {
        const text = await pdf.text()
        throw new Error(`Reporting service failed with status: ${pdf.status}, response: ${text}`)
      }
      const blob = await pdf.blob()
      savePdf(blob, 'report.pdf')
    } catch (err) {
      snackbar.showError('Something went wrong, unable to generate report.')
    } finally {
      setLoadingReport(false)
    }
  }

  const sendTestEmail = async () => {
    if (!companyId) {
      snackbar.showError('Select a company to send a test email')
      return
    }
    if (!emailUser) {
      snackbar.showError('Please select a user')
      return
    }
    try {
      const { data } = await sendCompanyMonthlyReportTestEmail({
        variables: {
          input: {
            companyId,
            month: month.month(),
            year: month.year(),
            userId: emailUser.id,
          },
        },
      })
      if (data) {
        const emails = data.sendCompanyMonthlyReportTestEmail.map((user) => user.email)
        snackbar.showSuccess(`Sent email to ${emails.join(', ')}`)
      }
    } catch (err) {
      console.error(err)
      snackbar.showError('Something went wrong')
    }
  }

  useEffect(() => {
    if (companyId) {
      getRecipients({ variables: { id: companyId } })
    }
  }, [companyId, getRecipients])

  const loadingIndicator = <CircularProgress style={{ color: 'grey', marginRight: 4 }} size={14} />
  const internalRecipients =
    companyUsers?.allProjectUsersByCompany
      .filter((companyUser) => companyUser.isSitelineAdmin)
      .map((companyUser) => companyUser.user) ?? []
  const externalUsers =
    companyUsers?.allProjectUsersByCompany
      .filter((companyUser) => !companyUser.isSitelineAdmin)
      .map((companyUser) => companyUser.user) ?? []
  const [externalRecipients, blockedRecipients] = _.partition(
    externalUsers,
    (user) => !user.blockedEmails.includes(EmailType.MONTHLY_REPORT)
  )

  return (
    <Page className={classes.root} title="Reporting">
      <Container maxWidth={false} className="container">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={4} className="header">
            <Typography variant="h4">Reporting</Typography>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader
                subheader="Generate a monthly report"
                subheaderTypographyProps={{ variant: 'overline' }}
              />
              <CardContent>
                <div className="generate">
                  <div className="row">
                    <CompanyAutocomplete
                      required
                      fullWidth
                      label="Company"
                      className={classes.input}
                      companyId={companyId}
                      setCompanyId={setCompanyId}
                    />
                  </div>
                  <div className="row">
                    <Typography variant="body2">Month:</Typography>
                    <Select
                      value={month.format('MMYYYY')}
                      onChange={(ev) => setMonth(moment.utc(ev.target.value as string, 'MMYYYY'))}
                    >
                      {months.map((monthOption) => {
                        const monthStr = monthOption.format('MMYYYY')
                        return (
                          <MenuItem key={monthStr} value={monthStr}>
                            {monthOption.format('MMM YYYY')}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                <Button
                  variant="outlined"
                  onClick={generateReport}
                  startIcon={loadingReport && loadingIndicator}
                  disabled={companyId === null || loadingReport}
                  className="button"
                >
                  Generate report
                </Button>
                <div className={clsx('recipients', { visible: loadingRecipients || companyUsers })}>
                  {loadingRecipients && loadingIndicator}
                  {companyUsers !== undefined && (
                    <>
                      {externalRecipients.length === 0 && (
                        <Typography variant="subtitle2" className="subtitle">
                          No users at this company have access to every project (or there are no
                          projects to report on)
                        </Typography>
                      )}
                      {externalRecipients.length > 0 && (
                        <>
                          <Typography variant="subtitle2" className="subtitle">
                            {externalRecipients.length} user(s) would receive this report:
                          </Typography>
                          <ul>
                            {externalRecipients.map((user) => (
                              <li key={user.id}>
                                <Typography>
                                  {user.firstName} {user.lastName} ({user.email})
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {blockedRecipients.length > 0 && (
                        <>
                          <Typography variant="subtitle2" className={clsx('marginTop', 'blocked')}>
                            {blockedRecipients.length} user(s) has disabled the reporting email:
                          </Typography>
                          <ul>
                            {blockedRecipients.map((user) => (
                              <li key={user.id}>
                                <Typography>
                                  {user.firstName} {user.lastName} ({user.email})
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {internalRecipients.length === 0 && (
                        <Typography variant="subtitle2" className={clsx('subtitle', 'marginTop')}>
                          No admin account is on all company projects, so no QA report would be sent
                          for this company
                        </Typography>
                      )}
                      {internalRecipients.length > 0 && (
                        <>
                          <Typography variant="subtitle2" className={clsx('subtitle', 'marginTop')}>
                            {internalRecipients.length} admin account(s) would receive the QA
                            report:
                          </Typography>
                          <ul>
                            {internalRecipients.map((user) => (
                              <li key={user.id}>
                                <Typography>
                                  {user.firstName} {user.lastName} ({user.email})
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.sendReport}>
            <Card>
              <CardHeader
                subheader="Send a monthly report test email"
                subheaderTypographyProps={{ variant: 'overline' }}
              />
              <CardContent>
                <Autocomplete
                  getOptionLabel={(option) => option.email}
                  options={admins}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  includeInputInList
                  onChange={(ev, value) => setEmailUser(value)}
                  value={emailUser}
                  multiple={false}
                  renderInput={(params) => <TextField {...params} label="Select recipient" />}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="sendButton"
                  onClick={sendTestEmail}
                  startIcon={sendingEmail && loadingIndicator}
                  disabled={!companyId || sendingEmail || !emailUser}
                >
                  Send test email
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
