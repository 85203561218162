import { gql } from '@apollo/client'
import { Container, Grid, Theme, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import {
  ContractProperties,
  DocumentExpirationFrequency,
  useGetProjectForContractCreateQuery,
} from '../../common/graphql/apollo-operations'
import { ContractCreateMetadata } from './ContractCreateMetadata'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  finished: {
    marginTop: theme.spacing(2),
  },
}))

gql`
  query getProjectForContractCreate($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      location {
        ...LocationProperties
      }
    }
  }
  ${fragments.location}
`

gql`
  query getCompanyForContractCreation($companyId: ID!) {
    company(id: $companyId) {
      ...CompanyProperties
      users {
        id
        user {
          ...UserProperties
        }
        role
        status
      }
    }
  }
  ${fragments.company}
  ${fragments.user}
`

gql`
  mutation addContract($input: AddContractInput!) {
    addContract(input: $input) {
      ...ContractProperties
    }
  }
  ${fragments.contract}
`

export interface LegalRequirementData {
  id?: string
  createdAt: moment.Moment
  name: string
  expirationFrequency: DocumentExpirationFrequency
  // If there are already documents for this requirement
  hasDocuments: boolean
  startDate?: string | null
  endDate?: string | null
  formTemplateId?: string | null
}

type ContractCreateParams = {
  projectId: string
}

export default function ContractCreate() {
  const classes = useStyles()
  const { projectId } = useParams() as ContractCreateParams
  const [contract, setContract] = useState<ContractProperties | undefined>(undefined)
  const [companyId, setCompanyId] = useState<string | null>(null)
  const navigate = useNavigate()

  const { data: projectData } = useGetProjectForContractCreateQuery({
    variables: { projectId },
  })

  if (!projectData?.project) {
    return null
  }

  const onCreate = (contractId: string) => {
    navigate(`/contracts/${contractId}`)
  }

  const title = 'New contract'
  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <ContractCreateMetadata
            projectId={projectId}
            companyId={companyId}
            setCompanyId={setCompanyId}
            contract={contract}
            setContract={setContract}
            onCreate={onCreate}
          />
        </Grid>
      </Container>
    </Page>
  )
}
