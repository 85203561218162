import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BarChartIcon from '@mui/icons-material/BarChart'
import CampaignIcon from '@mui/icons-material/Campaign'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import CodeIcon from '@mui/icons-material/Code'
import DescriptionIcon from '@mui/icons-material/Description'
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled'
import EditNoteIcon from '@mui/icons-material/EditNote'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import GroupIcon from '@mui/icons-material/Group'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Home from '@mui/icons-material/Home'
import KeyIcon from '@mui/icons-material/Key'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PersonIcon from '@mui/icons-material/Person'
import ReceiptIcon from '@mui/icons-material/Receipt'
import StorageIcon from '@mui/icons-material/Storage'
import Sync from '@mui/icons-material/Sync'
import SyncProblemIcon from '@mui/icons-material/SyncProblem'
import WorkIcon from '@mui/icons-material/Work'

export const HomeIcon = Home
export const UserIcon = PersonIcon
export const UsersIcon = GroupIcon
export const CompanyIcon = WorkIcon
export const CompaniesIcon = WorkIcon
export const ProjectIcon = AccountTreeIcon
export const ProjectsIcon = AccountTreeIcon
export const ContractIcon = ReceiptIcon
export const PayAppIcon = MonetizationOnIcon
export const PayAppsIcon = MonetizationOnIcon
export const FormTemplateIcon = DescriptionIcon
export const FormTemplatesIcon = DescriptionIcon
export const TemplateVariableIcon = CodeIcon
export const TemplateVariableLookupIcon = FindInPageIcon
export const TemplateSearchIcon = ManageSearchIcon
export const CachingIcon = StorageIcon
export const ReportingIcon = BarChartIcon
export const UserCampaignIcon = CampaignIcon
export const BackIcon = ArrowBackIcon
export const GcDedupeIcon = DomainDisabledIcon
export const CredentialsIcon = KeyIcon
export const MappingsIcon = EditNoteIcon
export const DisableIcon = HighlightOffIcon
export const SyncIcon = Sync
export const PendingSyncIcon = SyncProblemIcon
export const DemoIcon = CoPresentIcon
